import * as React from 'react';

import { Link, NavLink } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { NextArrow, PreviousArrow } from './Huren';

// @ts-ignore
import first from '../assets/images/bar brico/openings-weekend-4.webp';
// @ts-ignore
import second from '../assets/images/bar brico/bar-bricolage-1.webp';
// @ts-ignore
import third from '../assets/images/bar brico/openings-weekend-5.webp';
// @ts-ignore
import fourth from '../assets/images/bar brico/bar-bricolage-4.webp';
// @ts-ignore
import fifth from '../assets/images/bar brico/fifth.webp';
// @ts-ignore
import sixth from '../assets/images/bar brico/openings-weekend-2.webp';
// @ts-ignore
import seventh from '../assets/images/bar brico/bar-bricolage-3.webp';
// @ts-ignore
import eight from '../assets/images/bar brico/bar-bricolage-2.webp';
import '../styles/carousel-overrides.css';

export function BarBricolage() {
  return (
    <section className="w-screen">
      <div className="bg-svg bar-bricolage">
          &nbsp;
      </div>
      <div className="footer white-blue intro-text text-left flex flex-col items-end -mt-[15vw] mb-[8vw] lg:mb-0 lg:mt-0 space-y-[5vw] lg:space-y-0">
        <p className="lg:w-2/3 lg:py-[10vw] px-[2.5vw] lg:px-[2vw]">
          Bar Bricolage is de bar van de Chinastraat.
          Een groene oase in de Gentse stadswildernis,
          verstopt tussen de Gentse dokken en het industrieterrein.
          Een eigenzinnige plek met een decor van originele bouwwerken uit gerecycleerd
          en gerecupereerd materiaal, gemaakt in onze makerspaces.
          Een idyllische tuin waar jong en oud kan ontspannen en ontsnappen uit de
          drukte van de stad.
        </p>
        <svg className="hidden lg:block" width="100%" height="100%" viewBox="0 0 1920 375" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1020_113)">
            <path d="M53.25 374.08H320.54C322.86 373.95 325.19 373.88 327.54 373.88H858.64V374.08H1005.91C1007.15 374 1008.4 373.93 1009.65 373.88H1061.26V373.79H1232.41V155.66C1232.41 86.4 1288.76 30.05 1358.02 30.05H1764.23C1833.49 30.05 1889.84 86.4 1889.84 155.66V218.13C1889.84 287.39 1833.49 343.75 1764.23 343.75H1546.11V374.08H1693.28C1696.09 373.89 1698.91 373.79 1701.77 373.79H1919.89V0H1202.37V218.12C1202.37 287.38 1146.02 343.74 1076.76 343.74H1010.38C943.26 341.29 889.39 285.92 889.39 218.21V155.74C889.39 86.48 945.75 30.13 1015.01 30.13H1061.26V0.0899963H859.34V218.21C859.34 287.47 802.99 343.83 733.73 343.83H155.66C86.4 343.83 30.04 287.47 30.04 218.21V155.74C30.04 86.48 86.4 30.13 155.66 30.13H545.66V0.0899963H0V373.87H46.25C48.6 373.88 50.93 373.95 53.25 374.08Z" fill="#0659d4" />
          </g>
          <defs>
            <clipPath id="clip0_1020_113">
              <rect width="1920" height="374.08" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="lg:w-2/3 px-[2.5vw] lg:px-[2vw] pt-[8vw]">
          De zomermaanden (mei-september) zijn de drukste maanden voor Bar Bricolage.
          Dan kan je er van dinsdag tot en met zondag terecht.
          Op het menu staan cocktails, pintjes, limonades en andere lekkernijen,
          met een knipoog naar lokale en biologische producten.
        </p>
        <NavLink to="/agenda" className="self-start lg:self-end lg:w-2/3 underline pt-[4vw] pb-[4vw] px-[2.5vw] lg:px-[2vw]">
          AGENDA
        </NavLink>
        <p className="lg:w-2/3 px-[2.5vw] lg:px-[2vw] pb-[8vw]">
          Onze pizzabakker bakt kraakverse pizza en op zondag kan je brunchen.
          Blijven plakken doe je niet alleen voor de kaart. Onze wekelijkse,
          culturele agenda barst van live muziek, workshops, (rommel)markten,
          tentoonstellingen, dansavonden, jouw bedrijfsevenement, sport, spel,...
          Telkens met een hoek af.
          En dan heb je ook nog club Chinastraat,
          die voornamelijk op vrijdag en zaterdag de deuren opent.
        </p>
      </div>

      <Carousel
        className="relative"
        autoPlay
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        renderArrowPrev={PreviousArrow}
        renderArrowNext={NextArrow}
      >
        <img src={first} alt="first" />
        <img src={second} alt="second" />
        <img src={third} alt="third" />
        <img src={fourth} alt="fourth" />
        <img src={fifth} alt="fifth" />
        <img src={sixth} alt="sixth" />
        <img src={seventh} alt="seventh" />
        <img src={eight} alt="eight" />
      </Carousel>

      <svg className="hidden lg:block" width="100%" height="100%" viewBox="0 0 1920 375" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1020_113)">
          <path d="M1364.71 -1.95996V28.6H1761.59C1832.08 28.6 1889.43 85.95 1889.43 156.44V220.01C1889.43 290.5 1832.08 347.84 1761.59 347.84H1173.31C1102.82 347.84 1045.48 290.5 1045.48 220.01V-1.95996H1045H840H489.71V28.6H840H886.59C957.08 28.6 1014.43 85.95 1014.43 156.44V220.01C1014.43 290.5 957.08 347.84 886.59 347.84H840H298.31C227.82 347.84 170.48 290.5 170.48 220.01V-1.95996H0.280029V28.6H12.07C82.56 28.6 139.91 85.95 139.91 156.44V220.01C139.91 290.5 82.56 347.84 12.07 347.84H0.280029V376.04H1920V-1.95996H1364.71Z" fill="#ff69e2" />
        </g>
        <defs>
          <clipPath id="clip0_1020_113">
            <rect width="1920" height="374.08" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div className="questions text-left bb-green px-[2.5vw] lg:px-[2vw] py-[15vw] lg:py-[10vw] lg:-mt-1">
        <p className="color-theme black">
          Check de
          {' '}
          <Link to="/agenda" className="underline">agenda</Link>
          {' '}
          voor alle culturele uitspattingen.
        </p>
        <p className="flex flex-col items-end lg:space-y-[1vw]">
          <span className="bb-green mt-[1vw]">
            Vragen?
          </span>
          <span>
            Mail naar&nbsp;
            <a href="mailto:info@bar-bricolage.be" className="color-theme black underline ">
              info@bar-bricolage.be
            </a>
          </span>

          <span>
            Volg Bar Bricolage op&nbsp;
            <a
              href="https://www.instagram.com/barbricolage/"
              target="_blank"
              className="underline "
              rel="noreferrer"
            >
              Instagram&nbsp;
            </a>
            en&nbsp;
            <a
              href="https://www.facebook.com/BarBricolage/"
              target="_blank"
              className="underline "
              rel="noreferrer"
            >
              Facebook
            </a>
          </span>
        </p>
      </div>
    </section>
  );
}
