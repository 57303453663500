import * as React from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../../App';

export const colorDict = {
  green: '#00FFB1',
  black: '#000000',
  yellow: '#EFFF33',
  blue: '#0000FF',
};

export function ExpoSvg() {
  const theme = useContext(ThemeContext);
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_953_113)">
        <rect width="1920" height="1080" fill="white" />
        <path d="M1577.32 893.967V848.774H1548V893.967C1547.96 926.497 1535.03 957.683 1512.04 980.684C1489.04 1003.69 1457.87 1016.62 1425.36 1016.66H1380.19V1046.02H1745.12V1016.66H1700C1667.48 1016.64 1636.3 1003.7 1613.3 980.702C1590.3 957.699 1577.36 926.505 1577.32 893.967V893.967Z" fill="black" />
        <path d="M1845.66 186.009C1814.44 185.971 1784.5 173.544 1762.42 151.452C1740.34 129.36 1727.92 99.4084 1727.89 68.1672V24.7846L884 24.2344L734.79 24.1843H94.33V52.9193H404.94C471.36 52.9193 525.38 106.827 525.38 173.092V397.289C525.38 463.553 471.38 517.471 404.94 517.471H345C278.58 517.471 224.55 463.553 224.55 397.289V353.046H31V381.78H75.32C141.74 381.78 195.78 435.699 195.78 501.963V544.985H436.78C461.758 548.632 484.592 561.145 501.115 580.238C517.638 599.332 526.747 623.734 526.78 648.99V687.7H551.9V648.96C551.933 623.704 561.042 599.302 577.565 580.208C594.088 561.115 616.922 548.602 641.9 544.955H886.9V298.157H855.48V386.793C855.48 458.831 796.76 517.431 724.57 517.431H671.48C606.48 515.75 554.2 462.502 554.2 397.289V173.092C554.2 106.827 608.2 52.9193 674.64 52.9193H885.64L1581.98 52.9693C1613.21 53.0037 1643.14 65.4288 1665.22 87.5187C1687.3 109.609 1699.72 139.559 1699.76 170.801V214.203H1743.12C1774.35 214.235 1804.29 226.66 1826.37 248.753C1848.45 270.845 1860.88 300.8 1860.91 332.045V551.859C1860.87 583.102 1848.45 613.054 1826.37 635.144C1804.29 657.235 1774.35 669.659 1743.12 669.69H1699.76V697.875H1889V186.009H1845.66Z" fill="black" />
        <rect y="824.441" width="1920" height="273.241" fill="url(#paint0_linear_953_113)" />
        <path d="M177.22 915.939V947.565H69.0001V1014.87H195.51V1046.2H32.1401V824.441H192.41V855.757H69.0001V915.939H177.22Z" fill="black" />
        <path d="M210.7 824.441H250.7L300.92 906.324H301.23L351.76 824.441H391.76L322.3 932.367L397.3 1046.2H354.23L301.23 959.661H300.92L248.22 1046.2H205.12L280.12 932.988L210.7 824.441Z" fill="black" />
        <path d="M414.68 824.441H508.92C551.39 824.441 582.08 844.291 582.08 890.816C582.08 937.34 551.39 957.49 508.92 957.49H451.57V1046.2H414.68V824.441ZM451.57 925.864H507.37C532.17 925.864 545.19 915.308 545.19 890.846C545.19 866.653 532.19 855.827 507.37 855.827H451.57V925.864Z" fill="black" />
        <path d="M596.65 935.159C596.65 868.484 638.5 819.789 705.45 819.789C772.11 819.789 814.26 868.484 814.26 935.159C814.26 1001.83 772.11 1050.85 705.45 1050.85C638.5 1050.85 596.65 1001.84 596.65 935.159ZM777.37 935.159C777.37 890.505 756.29 851.415 705.45 851.415C654.61 851.415 633.23 890.505 633.23 935.159C633.23 980.182 654.62 1019.2 705.45 1019.2C756.28 1019.2 777.37 980.142 777.37 935.159Z" fill="black" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_953_113" x1="960" y1="824.441" x2="960" y2="1080" gradientUnits="userSpaceOnUse">
          <stop stopColor={colorDict[theme]} stopOpacity="0" />
          <stop offset="1" stopColor={colorDict[theme]} />
        </linearGradient>
        <clipPath id="clip0_953_113">
          <rect width="1920" height="1080" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
