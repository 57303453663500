import * as React from 'react';
import axios from 'axios';
import { PortableText } from '@portabletext/react';
import { DATASET, myPortableTextComponents, PROJECT_ID } from './Agenda';
import { cx } from '../utils';
import { ArrowIcon } from '../assets/banners/desktop/ArrowIcon';

const QUERY = '*[_type == "faq"]';

const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

interface FaqProps {
    question: string;
    answer: any;
}

interface FaqViewModel extends FaqProps {
    type?: 'bar_bricolage' | 'club';
}

function Faq({ question, answer }: FaqProps) {
  const [textIsOpen, setTextIsOpen] = React.useState(false);
  const textRef = React.useRef(null);

  return (
    <article className="flex flex-col items-start border-b-2 border-b-black">
      <button type="button" className="flex justify-between items-center gap-x-[5vw] pt-[3vw] pb-[1vw] lg:pt-[1.5vw] lg:pb-[0.5vw] w-full" onClick={() => setTextIsOpen(!textIsOpen)}>
        <h2 className="text-left intro-text font-bold">
          {question.charAt(0).toUpperCase()
                        + question.slice(1).toLowerCase()}
        </h2>

        <span className={cx(
          'h-[3vw] lg:h-[1.5vw] shrink-0 lg:hover:cursor-pointer lg:hover:underline vacancy-title !font-medium text-right transform transition-transform',
          { 'rotate-180': textIsOpen },
        )}
        >
          <ArrowIcon />
        </span>
      </button>
      <div
        style={
                    (textIsOpen && textRef && textRef.current)
                      ? { maxHeight: textRef.current.clientHeight }
                      : { maxHeight: 0 }
                }
        className="overflow-hidden transition-all duration-300"
      >
        <div ref={textRef} className="flex flex-col items-start intro-text pt-[1vw] pb-[3vw] lg:pt-[0.5vw] lg:pb-[1.5vw]">
          {!!answer && (
            <PortableText value={answer} components={myPortableTextComponents} className="huisregels-list-item text-left">{answer}</PortableText>
          )}
        </div>
      </div>
    </article>
  );
}

function Faqs() {
  const [barBricolageFaqs, setBarBricolageFaqs] = React.useState<FaqViewModel[]>([]);
  const [clubFaqs, setClubFaqs] = React.useState<FaqViewModel[]>([]);

  const splitFaqs = (faqs: FaqViewModel[]) => {
    const barBricolageFaqsTemp = faqs.filter((faq) => faq.type === 'bar_bricolage');
    const clubFaqsTemp = faqs.filter((faq) => faq.type === 'club');

    setBarBricolageFaqs(barBricolageFaqsTemp);
    setClubFaqs(clubFaqsTemp);
  };

  React.useEffect(() => {
    async function fetchFaqs() {
      try {
        const { data: { result } } = await axios.get(URL);

        splitFaqs(result);
      } catch (e) {
        console.error(e);
      }
    }

    fetchFaqs();
  }, []);

  return (
    <section className="w-screen pb-16">
      <h1 className="bg-svg faq">
            &nbsp;
      </h1>

      <div className="mt-[4vw] px-[2.5vw] mb-[8vw] lg:mb-[4vw]">
        <h2 className="text-left huren-title font-bold">
          Bar Bricolage
        </h2>
        {
            barBricolageFaqs.map(({ question, answer }) => (
              <Faq question={question} answer={answer} />
            ))
        }
      </div>

      <div className="mt-[4vw] px-[2.5vw] mb-[8vw] lg:mb-[4vw]">
        <h2 className="text-left huren-title font-bold">
          Club
        </h2>
        {
            clubFaqs.map(({ question, answer }) => (
              <Faq question={question} answer={answer} />
            ))
        }
      </div>
    </section>
  );
}

export default Faqs;
