import * as React from 'react';
import { useContext, useState } from 'react';
import axios from 'axios';
import { PortableText } from '@portabletext/react';
import { ThemeContext } from '../App';
import { cx } from '../utils';
import { myPortableTextComponents } from '../pages/Agenda';

interface NewsBannerProps {
    announcement: string;
}

const PROJECT_ID = 'w4h8hq3w';
const DATASET = 'production';
const QUERY = encodeURIComponent('*[_type == "news"]');

const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

interface NewsItem {
    title: string;
    text: any;
}

export function NewsBanner({ announcement }: NewsBannerProps) {
  const [news, setNews] = useState<NewsItem | undefined>();
  const [newsIsOpen, setNewsIsOpen] = useState(false);
  const theme = useContext(ThemeContext);

  const newsRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    async function fetchNews() {
      const { data: { result } } = await axios.get(URL);
      setNews(result[0]);
    }
    fetchNews();
  }, []);

  React.useEffect(() => {
    if (newsIsOpen) {
      setTimeout(() => {
        newsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
  }, [newsIsOpen]);

  function toggleNews() {
    setNewsIsOpen(!newsIsOpen);
  }

  return news && (
    <section>
      <button type="button" name="open-news" onClick={toggleNews} className={cx('w-screen flex flex-row overflow-hidden bg-black py-1.5 bg-theme-reverse', theme)}>
        <div className="inline-block news-banner-one">
          {[0, 1, 2, 3, 4, 6, 7, 8, 9].map((value) => (

            <span
              key={value}
              className={cx('intro-text-title px-10 color-theme-reverse', theme)}
            >
              {announcement}
            </span>
          ))}
        </div>

        <div className="inline-block news-banner-two">
          {[0, 1, 2, 3, 4, 6, 7, 8, 9].map((value) => (
            <span
              key={value}
              className={cx('intro-text-title px-10 color-theme-reverse user-select-none pointer-cursor', theme)}
            >
              {announcement}
            </span>
          ))}
        </div>
      </button>
      <div
        className="overflow-hidden bg-black text-white transition-all duration-300"
        style={(newsIsOpen && newsRef && newsRef.current)
          ? { maxHeight: newsRef.current.clientHeight }
          : { maxHeight: 0 }}
      >
        <div
          ref={newsRef}
          className="py-[20vw] text-left intro-text space-y-[1vw] ml-auto lg:w-2/3 px-[2.5vw]"
        >
          <h2 className="intro-text-title !mb-[2vw]">{news && news.title}</h2>
          {news && news.text && (
          <PortableText
            value={news.text}
            components={myPortableTextComponents}
          />
          )}
        </div>
      </div>
    </section>
  );
}
