import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { ClubSvg } from '../assets/banners/desktop/ClubSvg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/carousel-overrides.css';

// @ts-ignore
import first from '../assets/images/club/first.webp';
// @ts-ignore
import second from '../assets/images/club/second.webp';
// @ts-ignore
import third from '../assets/images/club/third.webp';
// @ts-ignore
import fourth from '../assets/images/club/fourth.webp';
// @ts-ignore
import fifth from '../assets/images/club/fifth.webp';

export function NextArrowClub(clickHandler): JSX.Element {
  return (
    <svg onClick={clickHandler} role="button" className="absolute top-[45%] right-[1vw] z-20" width="5%" height="10%" viewBox="0 0 56 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.15698 101.92L52.1071 51.9703L1.93701 1.80029" stroke="#DDDDDD" strokeWidth="5" />
    </svg>
  );
}

export function PreviousArrowClub(clickHandler): JSX.Element {
  return (
    <svg onClick={clickHandler} role="button" className="absolute top-[45%] left-[1vw] z-20" width="5%" height="10%" viewBox="0 0 56 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.9148 2.29102L3.96484 52.241L54.1348 102.411" stroke="#DDDDDD" strokeWidth="5" />
    </svg>

  );
}

export function Club() {
  return (
    <section className="w-screen bg-black">
      <h1 className="bg-svg"><ClubSvg /></h1>
      <article className="intro-text text-left flex flex-col items-end px-[2.5vw] pb-[10vw] lg:py-[10vw] lg:px-12 -mt-[10vw] lg:mt-0">
        <p className="lg:w-2/3 color-theme black ">
          Club Chinastraat opent elk weekend de deuren voor grote feesten en concepten,
          maar ook kleine initiatieven kunnen een duwtje in de rug krijgen in de Chinastraat.
        </p>

        <NavLink to="/agenda" className="color-theme black self-start lg:self-end lg:w-2/3 underline py-[10vw] lg:py-[2.5vw]">
          AGENDA
        </NavLink>

        <p className="lg:w-2/3 pb-[5vw] color-theme black">
          Samen met Stad Gent, Moodfamily, Rave Alert, Giegling, Splén,
          Hip Hop Hooray, Democrazy, Narcosis, Space Safari, Kontrakult,
          Breakcore Gives Me Wood & Dub Garden kwamen hier al bruisende events tot stand.
        </p>

      </article>

      <Carousel
        className="relative"
        autoPlay
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        renderArrowPrev={PreviousArrowClub}
        renderArrowNext={NextArrowClub}
      >
        <img src={first} alt="first" />
        <img src={second} alt="second" />
        <img src={third} alt="third" />
        <img src={fourth} alt="fourth" />
        <img src={fifth} alt="fifth" />
      </Carousel>

      <div className="bg-black hidden lg:block relative">
        <div
          className="absolute top-0 left-0 right-0 h-[400px] z-20 linear-gradient black-club rotate-180"
        >
        &nbsp;
        </div>
        <svg className="z-10" width="100%" height="100%" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M705.04 94.5398V143.95C705.04 217.93 765.23 278.12 839.21 278.12H888.62V310.21H839.21C765.23 310.21 705.04 370.4 705.04 444.38V493.78H672.95V94.5298H705.04V94.5398Z" stroke="#DDDDDD" strokeWidth="3" />
          <path d="M30.25 159.79H53.06C108.88 159.79 154.28 114.39 154.28 58.5697V35.7598H298.98V56.4298H276.17C220.35 56.4298 174.95 101.83 174.95 157.64V180.45H152.14C96.33 180.45 50.93 225.85 50.93 281.67V306.62C50.93 362.44 96.33 407.84 152.14 407.84H174.95V430.65C174.95 486.46 220.35 531.86 276.17 531.86H298.98V552.53H154.28V529.72C154.28 473.9 108.88 428.5 53.06 428.5H30.25V159.79Z" stroke="#DDDDDD" strokeWidth="3" />
          <path d="M1890.29 36.71V65.0699H1846.61C1781.19 65.0699 1727.97 118.3 1727.97 183.71V227.39H1684.29C1618.87 227.39 1565.65 280.62 1565.65 346.03V389.71H1521.97C1456.55 389.71 1403.34 442.93 1403.34 508.34V552.03H1359.65C1294.23 552.03 1241.02 605.25 1241.02 670.66V714.34H1035.02C969.6 714.34 916.38 767.57 916.38 832.98V891.97C916.38 957.39 969.61 1010.6 1035.02 1010.6H1890.3V1039H888V685.99H1094C1159.42 685.99 1212.63 632.76 1212.63 567.35V523.67H1256.32C1321.74 523.67 1374.95 470.45 1374.95 405.04V361.35H1418.63C1484.05 361.35 1537.27 308.13 1537.27 242.72V199.03H1580.95C1646.37 199.03 1699.58 145.81 1699.58 80.4V36.72H1890.29V36.71Z" stroke="#DDDDDD" strokeWidth="3" />
          <path d="M650.75 1025.06H622.34V981.31C622.34 915.79 569.02 862.47 503.5 862.47H459.75V818.72C459.75 753.2 406.43 699.88 340.91 699.88H297.16V671.47H488.18V715.22C488.18 780.74 541.49 834.05 607.01 834.05H650.76V1025.06H650.75Z" stroke="#DDDDDD" strokeWidth="3" strokeMiterlimit="10" />
        </svg>
        <div
          className=" absolute left-0 right-0 bottom-0 h-[400px] z-20 linear-gradient black-club"
        >
          &nbsp;
        </div>
      </div>
      <article className="questions text-left color-theme bg-theme black px-[2.5vw] lg:px-[2vw] py-[15vw] lg:py-[10vw] lg:-mt-1">
        <p className="flex flex-col items-end lg:space-y-[1vw]">
          <span className="mt-[1vw]">
            Vragen?
          </span>
          <span>
            Mail naar&nbsp;
            <a href="mailto:info@chinastraat.be" className="color-theme black underline ">
              info@chinastraat.be
            </a>
          </span>

          <span>
            Volg Chinastraat op&nbsp;
            <a
              href="https://www.instagram.com/chinastraat/"
              target="_blank"
              className="underline "
              rel="noreferrer"
            >
              Instagram&nbsp;
            </a>
            en&nbsp;
            <a
              href="https://www.facebook.com/chinastraat"
              target="_blank"
              className="underline "
              rel="noreferrer"
            >
              Facebook
            </a>
          </span>
        </p>
      </article>
    </section>
  );
}
