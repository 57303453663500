import * as React from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ExpoSvg } from '../assets/banners/desktop/ExpoSvg';
import { ThemeContext } from '../App';
import { cx } from '../utils';

export function ExpoRuimte() {
  const theme = useContext(ThemeContext);

  return (
    <section className="w-screen">
      <div className=" lg:hidden bg-svg expo">
            &nbsp;
      </div>
      <div className="bg-svg hidden lg:block"><ExpoSvg /></div>

      <div className={cx('bg-theme-m color-theme-m intro-text text-left flex flex-col items-end lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[10vw] lg:mt-0', theme)}>
        <p className=" lg:w-2/3">
          Ontsteking is een 500m² expo en projectruimte voor actuele kunst,
          opgericht en gerund door kunstenaarscollectief NADAR.
          Deze ruimte stelt zich open voor andere collectieven en organisaties,
          om samen te zoeken naar alternatieven op hoe kunst vandaag getoond en gepresenteerd wordt.
        </p>
        <NavLink to="/agenda" className="self-start lg:self-end lg:w-2/3 underline py-[10vw] lg:py-[2.5vw]">
          AGENDA
        </NavLink>
        <p className=" lg:w-2/3">
          Ontsteking gaat op zoek naar projecten die verder gaan dan de standaard.
          Kunst die niet enkel getoond, maar ook ervaren wordt. Dit kan via tentoonstellingen,
          boekvoorstellingen, lezingen, filmvoorstellingen en optredens. Het streeft ernaar
          een neutrale ruimte voor artiesten aan te bieden, waar ze vrij kunnen experimenteren
          met de verschillende manier van het tonen.
        </p>
      </div>

      <div
        className={cx('h-20 z-20 lg:hidden linear-gradient mt-12', theme)}
      >
        &nbsp;
      </div>
      <div className={cx('bg-theme-d color-theme-d questions flex flex-col justify-center bg-white text-left expo-bg-svg mb-[15vw] lg:-mb-[10vw]', theme)}>
        <div className="lg:bg-white px-[2.5vw] lg:px-[2vw] py-[15vw] lg:py-[9.2vw]  lg:mb-0">
          <p className=" mb-6 lg:mb-12">
            Vragen?
          </p>
          <p className="flex flex-col items-end space-y-[1vw]">
            <span>
              Mail naar&nbsp;
              <a href="mailto:info@ontsteking.org" className="underline ">
                info@ontsteking.org
              </a>
            </span>

            <span>
              Volg Ontsteking op&nbsp;
              <a
                href="https://www.instagram.com/ontstekinggent/"
                target="_blank"
                className="underline "
                rel="noreferrer"
              >
                Instagram&nbsp;
              </a>
              en&nbsp;
              <a
                href="https://www.facebook.com/ontstekinggent"
                target="_blank"
                className="underline "
                rel="noreferrer"
              >
                Facebook
              </a>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
