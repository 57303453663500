import Lottie from 'react-lottie-player';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
// @ts-ignore
import ChinastraatAnimation from '../assets/banners/animation/chinastraat-animation.json';
// @ts-ignore
import ChinastraatAnimationMobile from '../assets/banners/animation/chinastraat-animation-mobile.json';
import { ThemeContext } from '../App';

export function Chinastraat() {
  const theme = useContext(ThemeContext);

  const word = 'VACATURES\u00A0VACATURES\u00A0VACATURES\u00A0';

  React.useEffect(() => {}, []);

  return (
    <section className="relative w-screen -mb-36 -mb-[30%] lg:-mb-[8%]">
      <h1 className="lg:hidden">
        <Lottie animationData={ChinastraatAnimationMobile} loop play />
      </h1>
      <h1 className="hidden lg:block">
        <Lottie animationData={ChinastraatAnimation} loop play />
      </h1>
      <div className="w-full flex justify-end ">
        <Link
          to="/vacatures"
          type="button"
          id="rotating-button"
          className={`circle ${theme} mr-[28vw] -mt-[11vw] lg:mt-0 lg:absolute lg:top-[6vw] lg:left-[12vw]`}
          style={{ '--word-length': `"${word.length}"` } as React.CSSProperties}
        >
          {word.split('').map((letter, index) => {
            const style = { '--char-index': index } as React.CSSProperties;
            return (<span className={`char color-theme-reverse ${theme}`} key={letter} style={style}>{letter}</span>);
          })}

        </Link>
      </div>
    </section>
  );
}
