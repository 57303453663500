import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MakersSvg } from '../assets/banners/desktop/MakersSvg';
import { cx } from '../utils';
import { ThemeContext } from '../App';

export function Makers() {
  const theme = useContext(ThemeContext);

  const [isPictureVisible, setIsPictureVisible] = React.useState(false);
  const [activePicture, setActivePicture] = React.useState('sarah');

  function handleMouseEnter(name: string) {
    if (name !== 'picture') setActivePicture(name);
    setIsPictureVisible(true);
  }

  function handleMouseLeave() {
    setIsPictureVisible(false);
  }

  return (
    <section className="w-screen relative">
      <div className=" lg:hidden bg-svg makers">
        &nbsp;
      </div>
      <div className="bg-svg hidden lg:block"><MakersSvg /></div>

      <div className={cx('bg-theme-m color-theme-m intro-text text-left flex flex-col items-end lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[11vw] lg:mt-0 space-y-12', theme)}>
        <p className="lg:w-2/3">
          {/* eslint-disable-next-line max-len */}
          Naast een evenementenlocatie en centrum voor stadscultuur is Chinastraat vooral een toevluchtsoord
          {/* eslint-disable-next-line max-len */}
          en een platform voor creatie en kruisbestuiving tussen verschillende residenten.
          Makers uit diverse sectoren (beeldende kunst,
          automechanica, muziek, verschillende ambachten,...) ontmoeten
          {/* eslint-disable-next-line max-len */}
          elkaar met als uitkomst: nieuwe ideeën en creaties. Chinastraat en Bar Bricolage worden mee vormgegeven door
          dit artistiek gezelschap.
        </p>
      </div>

      <div className="-z-20 w-full makers-names makers-bg-svg mt-[5vw]">
        <div
          className={cx(
            'fixed w-screen h-screen top-0 bottom-0 left-0 right-0 flex justify-center items-center pointer-events-none -z-10',
          )}
        >
          <div
            className={cx(
              'h-[70vh] w-[60vw] lg:h-[60vh] lg:w-[40vw] mx-auto my-auto bg-black pointer-events-none makers-background-image transition-opacity duration-500',
              activePicture,
              { 'opacity-0': !isPictureVisible },
              { 'opacity-100': isPictureVisible },
            )}
          >
            &nbsp;
          </div>
        </div>
        <div className="flex flex-col items-start bg-transparent gap-y-[9.5vw] pt-[16vw] pb-[4vw] lg:pb-[16vw] z-30">
          <div
            className="inline-block ml-[24%] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('sarah')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.sarahgeirnaert.com/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Sarah Geirnaert
            </a>
          </div>
          <div
            className="inline-block ml-[60%] -mt-[4vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('adriaan')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/adriaan.vlerick/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Adriaan Vlerick
            </a>
          </div>
          <div
            className="inline-block ml-[10%] -mt-[10vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('lander')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/ateljeebruwjee/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Lander Bruwier
            </a>
          </div>
          <div
            className="inline-block ml-[60%] -mt-[4vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('nadar')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/nadarcollective/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              NADAR
            </a>
          </div>
          <div
            className="inline-block ml-[15%] -mt-[7vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('simon')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/simonheyde/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Simon Heyde
            </a>
          </div>
          <div
            className="inline-block ml-[56%] -mt-[8vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('jeff-s')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/jeffseverins/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Jeff Severins
            </a>
          </div>
          <div
            className="inline-block ml-[25%] -mt-[1vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('hypochristmutreefuzz')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://hypochristmutreefuzz.bandcamp.com" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Hypochrismutreefuzz
            </a>
          </div>
          <div
            className="inline-block ml-[8%] -mt-[1vw]  hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('piet')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)}>
              Piet Vermeire
            </a>
          </div>
          <div
            className="inline-block ml-[64%] -mt-[12vw]  hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('silas')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)}>
              Silas Joos
            </a>
          </div>
          <div
            className="inline-block ml-[29%] -mt-[5vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('kabunga')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.facebook.com/kabungaquintet/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Kabunga
            </a>
          </div>
          <div
            className="inline-block ml-[71%] -mt-[8vw] hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('velofwijf')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/velowijf_/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Velowijf
            </a>
          </div>
          <div
            className="inline-block ml-[19%]  -mt-[8vw]  hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('delphine')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/llllllllllslll/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Delphine De Smet
            </a>
          </div>
          <div
            className="inline-block ml-[62%]  hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('bianca')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/biancaanddaniel/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Bianca & Daniel
            </a>
          </div>
          <div
            className="inline-block ml-[26%] -mt-[6vw]  hover:cursor-pointer"
            onPointerEnter={() => handleMouseEnter('atelier')}
            onPointerLeave={() => handleMouseLeave()}
          >
            <a href="https://www.instagram.com/atelier.vive/" target="_blank" className={cx('inline-block whitespace-nowrap bg-theme color-theme', theme)} rel="noreferrer">
              Atelier Vive
            </a>
          </div>
        </div>
      </div>

      <div
        className={cx('h-20 lg:hidden linear-gradient mt-[15vw]', theme)}
      >
            &nbsp;
      </div>
      <div className={cx('zie-ook bg-theme-d color-theme-d questions text-left px-[2.5vw] lg:px-[2vw] py-[15vw] lg:py-[7vw]', theme)}>
        <p className=" my-6 lg:my-12">
          Zie ook
        </p>

        <p className="flex flex-col items-end space-y-2 lg:space-y-8">
          <Link to="/medialab" className="underline ">
            Medialab
          </Link>
        </p>
      </div>
      <div className="adriaan" />
      <div className="atelier" />
      <div className="bianca" />
      <div className="kabunga" />
      <div className="lander" />
      <div className="nadar" />
      <div className="piet" />
      <div className="sarah" />
      <div className="silas" />
      <div className="simon" />
      <div className="velofwijf" />
      <div className="delphine" />
      <div className="jeff-s" />
      <div className="hypochristmutreefuzz" />
    </section>
  );
}
