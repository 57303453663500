import {
  useState,
} from 'react';
import * as React from 'react';
import { clsx } from 'clsx';

interface CustomFormProps {
    theme: 'green' | 'blue' | 'yellow' | 'white-blue' | 'black' | 'full-black';
}

export default function CustomForm({ theme }:CustomFormProps) {
  const [email, setEmail] = useState('');

  return (
    <form
      action="https://chinastraat.us21.list-manage.com/subscribe/post?u=52fee93fcf21c5d52697ef487&amp;id=7338a167f2&amp;f_id=008feae6f0"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="w-full mt-[1vw] mb-[6vw] lg:w-[41.5vw] lg:mb-0"
      target="_blank"
    >
      <div id="mc_embed_signup_scroll">
        <div className="flex w-full">
          <input
            type="email"
            name="EMAIL"
            className={clsx(
              'bg-transparent outline-0 border-t-[0.7vw] lg:border-t-[0.3vw] border-l-[0.7vw] lg:border-l-[0.3vw] border-b-[0.7vw] lg:border-b-[0.3vw] border-white py-[0.4vw] pl-[1vw] required email w-full',
              {
                '!border-black': theme === 'green' || theme === 'blue' || theme === 'yellow' || theme === 'white-blue',
                'border-bb-blue': theme === 'white-blue',
              },
            )}
            id="mce-EMAIL"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            className={clsx(
              'border-[0.7vw] lg:border-[0.3vw] border-white hover:bg-white hover:text-black py-[0.4vw] px-[1vw]',
              {
                '!border-black hover:!bg-black ': theme === 'green' || theme === 'blue' || theme === 'yellow',
                'border-bb-blue hover:!bg-bb-blue hover:text-white': theme === 'white-blue',
                'hover:!text-china-yellow': theme === 'yellow',
                'hover:!text-china-green': theme === 'green',
                'hover:!text-china-blue': theme === 'blue',
              },
            )}
            value="SIGN UP"
          />
        </div>
        <div id="mce-responses" className="clear foot">
          <div className="response hidden" id="mce-error-response" />
          <div className="response hidden" id="mce-success-response" />
        </div>
        <div aria-hidden="true" className="hidden">
          <input type="text" name="b_a1c7faa94f950e4eb6b19ce50_b1c792c625" tabIndex={-1} value="" />
        </div>
      </div>
    </form>
  );
}
