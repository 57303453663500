import * as React from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../../../App';
import { colorDict } from './ExpoSvg';

export function MakersSvg() {
  const theme = useContext(ThemeContext);

  return (
    <svg width="100%" height="100%" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1065_148)">
        <rect y="812.975" width="1920" height="273.241" fill="url(#paint0_linear_1065_148)" />
        <path d="M1904.62 199.64H1859.08C1790.89 199.64 1735.4 144.15 1735.4 75.9599V30.4199H894.56V59.9599L1582.14 59.9999C1650.34 59.9999 1705.83 115.48 1705.83 183.68V229.23H1751.37C1819.57 229.23 1875.06 284.71 1875.06 352.91V583.63C1875.06 651.83 1819.57 707.31 1751.37 707.31H1705.83V736.9H1904.63V199.64H1904.62Z" fill="black" />
        <path d="M184.75 728.24C184.83 728.24 184.92 728.24 185 728.24H245.92C246 728.24 246.09 728.24 246.17 728.24H519.97C520.05 728.24 520.14 728.24 520.22 728.24H836.13V699.02H520.22C452.67 699.02 397.72 644.22 397.72 576.85V348.95C397.72 281.59 452.67 226.78 520.22 226.78H581.14C648.69 226.78 703.64 281.59 703.64 348.95V393.93H900.56V364.72H855.45C787.9 364.72 732.94 309.91 732.94 242.55V181.8C732.94 114.44 787.9 59.6399 855.45 59.6399H900.56V30.4199H703.65V75.3899C703.65 142.76 648.7 197.57 581.15 197.57H520.23C452.68 197.57 397.73 142.75 397.73 75.3899V30.4199H368.43V75.3899C368.43 137.68 321.45 189.23 260.97 196.65L30.1699 195.08V379.2H61.9999V359.62C61.9999 286.4 121.72 226.82 195.14 226.82H249.14C315.21 228.53 368.42 282.66 368.42 348.94V576.84C368.42 644.21 313.47 699.01 245.92 699.01H31.3599V728.19" fill="black" />
        <path d="M30.7798 846.29H75.2898L133.09 1005.24H133.67L191.18 846.29H235.69V1052.93H202.17V896.29H201.59L146.97 1052.93H119.51L64.8898 896.29H64.3098V1052.93H30.7798V846.29Z" fill="black" />
        <path d="M305.91 1004.08L289.15 1052.92H254.18L330.19 846.28H366.31L442.03 1052.92H407.06L390.3 1004.08H305.91ZM381.05 975.47L348.68 877.79H348.1L315.44 975.47H381.05Z" fill="black" />
        <path d="M496.07 846.29V948.31L589.71 846.29H630.75L550.41 933.86L635.67 1052.93H595.5L527.87 957.85L496.08 991.95V1052.93H461.69V846.29H496.07Z" fill="black" />
        <path d="M787.96 931.54V961.02H687.1V1023.73H805.01V1052.92H652.71V846.29H802.12V875.48H687.1V931.55H787.96V931.54Z" fill="black" />
        <path d="M833.04 846.29H921.19C963.67 846.29 991.13 867.96 991.13 905.54C991.13 934.44 976.39 951.2 952.69 956.98V957.56C1000.38 966.52 983.04 1047.44 994.02 1050.91V1052.93H957.9C948.36 1044.26 967.73 973.17 918.02 973.17H867.44V1052.93H833.05V846.29H833.04ZM867.43 943.68H913.96C935.06 943.68 957.02 939.63 957.02 909.58C957.02 879.81 935.06 875.48 913.96 875.48H867.43V943.68V943.68Z" fill="black" />
        <path d="M1175.5 996.86C1175.5 1029.81 1150.65 1057.26 1098.63 1057.26C1048.05 1057.26 1009.62 1030.96 1007.59 984.14H1041.98C1043.71 1010.44 1061.63 1027.78 1098.62 1027.78C1129.25 1027.78 1141.1 1014.2 1141.1 998.3C1141.1 973.73 1123.47 969.11 1082.72 959C1050.35 950.91 1015.38 938.48 1015.38 899.18C1015.38 862.48 1043.7 841.96 1088.79 841.96C1134.16 841.96 1167.11 865.66 1170 908.14H1135.61C1132.43 884.15 1117.4 871.44 1088.79 871.44C1064.23 871.44 1049.49 880.69 1049.49 896.01C1049.49 918.55 1068.85 922.6 1097.75 929.82C1141.98 941.08 1175.5 950.62 1175.5 996.86Z" fill="black" />
        <path d="M1202.38 846.29H1290.24C1329.83 846.29 1358.44 864.79 1358.44 908.14C1358.44 951.49 1329.83 970.28 1290.24 970.28H1236.78V1052.93H1202.39V846.29H1202.38ZM1236.77 940.79H1288.79C1311.91 940.79 1324.05 930.96 1324.05 908.13C1324.05 885.59 1311.91 875.47 1288.79 875.47H1236.77V940.79Z" fill="black" />
        <path d="M1399.76 1004.08L1383 1052.92H1348.03L1424.04 846.28H1460.16L1535.88 1052.92H1500.91L1484.15 1004.08H1399.76ZM1474.9 975.47L1442.53 877.79H1441.95L1409.29 975.47H1474.9Z" fill="black" />
        <path d="M1632.99 871.43C1588.48 871.43 1570.86 908.71 1570.86 949.46C1570.86 990.5 1588.49 1027.78 1632.99 1027.78C1671.43 1027.78 1686.17 1002.64 1688.48 977.49H1722.87C1718.25 1024.02 1686.45 1057.25 1633.86 1057.25C1574.33 1057.25 1536.76 1011.88 1536.76 949.45C1536.76 887.32 1574.33 841.94 1633.86 841.94C1686.17 841.94 1717.96 875.17 1722.87 917.37H1688.48C1684.72 896.58 1671.14 871.43 1632.99 871.43Z" fill="black" />
        <path d="M1883.55 931.54V961.02H1782.69V1023.73H1900.6V1052.92H1748.3V846.29H1897.71V875.48H1782.69V931.55H1883.55V931.54Z" fill="black" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1065_148" x1="960" y1="812.975" x2="960" y2="1086.22" gradientUnits="userSpaceOnUse">
          <stop stopColor={colorDict[theme]} stopOpacity="0" />
          <stop offset="1" stopColor={colorDict[theme]} />
        </linearGradient>
        <clipPath id="clip0_1065_148">
          <rect width="1920" height="1080" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
