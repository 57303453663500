import * as React from 'react';

export function AgendaSvg() {
  return (
    <>
      <svg
        id="Laag_1"
        data-name="Laag 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 375 435"
        className="lg:hidden"
      >
        <title>Agenda</title>
        <defs>
          <clipPath id="clip-path">
            <rect className="cls-1" x="11.05" y="10.4" width="354" height="354" />
          </clipPath>
        </defs>
        <g id="AGENDA" className="cls-2">
          <text className="cls-3" transform="translate(11.06 429.4)">
            <tspan>A</tspan>
            <tspan className="cls-4" x="36.6" y="0">GEN</tspan>
            <tspan x="157.62" y="0">D</tspan>
            <tspan className="cls-5" x="195.9" y="0">A</tspan>
          </text>
        </g>
        <g id="Groupe_408" data-name="Groupe 408">
          <g className="cls-6">
            <g id="Groupe_364" data-name="Groupe 364">
              <path
                id="Tracé_87"
                data-name="Tracé 87"
                d="M364.05,364H354V348.54a41.91,41.91,0,0,0-41.86-41.86H296.77V291.27a41.91,41.91,0,0,0-41.86-41.86H239.5V234a41.89,41.89,0,0,0-41.85-41.85H182.23V176.73a41.9,41.9,0,0,0-41.86-41.85H125V62.2A41.91,41.91,0,0,0,83.1,20.34H62.29A41.91,41.91,0,0,0,20.44,62.2V364h-10V10.33H135V83a41.91,41.91,0,0,0,41.86,41.85h15.41v15.42a41.89,41.89,0,0,0,41.85,41.85h15.42v15.42a41.91,41.91,0,0,0,41.85,41.86h15.42v15.41a41.89,41.89,0,0,0,41.86,41.85h15.41Z"
              />
            </g>
          </g>
        </g>
      </svg>
      <svg
        width="100%"
        height="100%"
        version="1.1"
        id="Laag_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1080"
        xmlSpace="preserve"
        className="lg:block hidden"
      >
        <title>Agenda</title>

        <path d="M1286.28,490.41V445.7h-29.03v44.71c0,66.94-54.46,121.39-121.39,121.39h-44.71v29.03h361.23V611.8h-44.7
	C1340.73,611.8,1286.28,557.34,1286.28,490.41z"
        />
        <path d="M229.21,219.84l58.84,0c65.25,0,118.32,52.94,118.32,118V529.5h0.08V647h28.21V529.5h0.01V337.85
	c0-65.06,53.08-118,118.32-118h58.84c65.25,0,118.33,52.94,118.33,118v43.45h190.2v-28.22h-43.57
	c-65.25,0-118.33-52.94-118.33-118.01v-58.68c0-65.06,53.09-117.99,118.33-117.99h36.57l0-0.01l220.17,0.33
	c65.44,0.41,118.54,53.76,118.54,119.29v43.94h28.53v-43.94c0-65.13,52.46-118.22,117.34-119.28l179.55,0.01
	c65.87,0,119.47,53.59,119.47,119.46v44h43.99c65.87,0,119.47,53.58,119.47,119.46v222.86c0,65.88-53.6,119.46-119.47,119.46h-43.99
	v28.58H1889V193.63h-43.99c-65.87,0-119.46-53.6-119.46-119.47V30.17h-281.7v0h-355v0H920.38v0h-190.2v43.44
	c0,65.07-53.08,118.02-118.33,118.02H553c-65.25,0-118.32-52.95-118.32-118.02V30.17h-28.3v43.44
	c0,65.07-53.08,118.02-118.32,118.02H28.39v28.22h7.28"
        />
        <text transform="matrix(1 0 0 1 30.0971 1050.3365)" className="st23 st24 st25">AGENDA</text>
      </svg>

    </>

  );
}
