import * as React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { cx } from '../utils';

// @ts-ignore
import realFirst from '../assets/images/huren/1-NIEUW-bedrijfsfeest.webp';
// @ts-ignore
import realSecond from '../assets/images/huren/1-NIEUW-exporuimte.webp';
// @ts-ignore
import first from '../assets/images/huren/bedrijfsfeest.webp';
// @ts-ignore
import second from '../assets/images/huren/bedrijfsfeest-2.webp';
// @ts-ignore
import third from '../assets/images/huren/de grote zaal.webp';
// @ts-ignore
import fourth from '../assets/images/huren/de hal.webp';
// @ts-ignore
import seventh from '../assets/images/huren/tuin.webp';
import '../styles/carousel-overrides.css';

const ruimtes = [
  { name: 'De Hal', description: 'Een polyvalente ruimte van 600m² met green key, geschikt voor film-of theaterproducties, ontvangen van grote groepen, recepties, beurzen, markten, nachtparking, grote bouwprojecten, workshops,...' },
  { name: 'De Exporuimte', description: 'Een multifunctionele ruimte van 500m² met prachtige lichtinval.' },
  { name: 'De Grote Zaal', description: 'Een multifunctionele verduisterde club- en concertzaal van 450m². Voorzien van een volwaardige geluid- en lichtinstallatie. Mogelijk om te compartimenteren.' },
  { name: 'De Kleine Zaal', description: 'Een multifunctionele verduisterde club-en concertzaal van 150m².' },
  {
    name: 'De Tuin',
    description: 'Een groene oase inclusief overdekt gedeelte, veel zitgelegenheden en de mogelijkheid tot verwarmde tenten.',
  },
  {
    name: 'De Refter',
    description: ['Een ideale ruimte als productiekantoor, make-up ruimte, refter, … '],
  },
];

export function NextArrow(clickHandler): JSX.Element {
  return (
    <svg role="button" onClick={clickHandler} className="absolute top-[45%] right-[1vw] z-20" width="5%" height="10%" viewBox="0 0 57 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.62573 102.06L52.5758 52.1099L2.40576 1.93994" stroke="white" strokeWidth="5" />
    </svg>
  );
}

export function PreviousArrow(clickHandler): JSX.Element {
  return (
    <svg role="button" onClick={clickHandler} className="absolute top-[45%] left-[1vw] z-20" width="5%" height="10%" viewBox="0 0 56 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.95 1.97998L4 51.93L54.17 102.1" stroke="white" strokeWidth="5" />
    </svg>
  );
}

export function Huren() {
  const [success, setSuccess] = React.useState(false);

  const [fullName, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [organization, setOrganization] = React.useState<string>('');
  const [what, setWhat] = React.useState<string>('');
  const [desiredDates, setDesiredDates] = React.useState<string>('');
  const [expectedAttendance, setExpectedAttendance] = React.useState<string>('');
  const [entranceFee, setEntranceFee] = React.useState<string>('');
  const [additional, setAdditional] = React.useState<string>('');
  const [idealDay, setIdealDay] = React.useState<string>('');

  const [error, setError] = React.useState<boolean>(false);

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setOrganization('');
    setWhat('');
    setDesiredDates('');
    setExpectedAttendance('');
    setEntranceFee('');
    setAdditional('');
    setIdealDay('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (fullName === ''
        || email === ''
        || phoneNumber === ''
        || what === ''
        || desiredDates === ''
        || expectedAttendance === ''
        || entranceFee === '') {
      setError(true);
      setSuccess(false);
    } else {
      setError(false);
      const myForm: any = document.getElementById('rental');
      const formData: any = new FormData(myForm);
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          setSuccess(true);
          resetForm();
        })
        .catch((e) => console.error(e));
    }
  };

  const formSection = React.useRef<HTMLElement>();

  function scrollToForm() {
    formSection.current.scrollIntoView({ block: 'center' });
  }

  function scrollSmoothToForm() {
    formSection.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  React.useEffect(() => {
    if (window.location.search.includes('success=true')) {
      setSuccess(true);
      setTimeout(() => {
        scrollToForm();
      }, 100);
    }
  }, []);

  React.useEffect(() => {
    if (error || success) {
      scrollSmoothToForm();
    }
  }, [error, success]);

  return (
    <section className="w-screen">
      <h1 className="bg-svg huren">
          &nbsp;
      </h1>

      <article className={cx('intro-text text-left flex flex-col items-end pb-[10vw] lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[10vw] lg:mt-0')}>
        <p className="lg:w-2/3 intro-text">
          {/* eslint-disable-next-line max-len */}
          Iets feestelijk van plan? Op zoek naar een plek voor een (bedrijfs)evenement? Een film-of theaterproductie? Een tentoonstelling? Een teambuilding? Welkom in Chinastraat! We hebben verschillende ruimtes ter beschikking, waaronder onze tuin, de grote hal en een prachtige tentoonstellingsruimte.
        </p>
        <p className="lg:w-2/3 intro-text mt-[6vw] lg:mt-[3vw]">
          {/* eslint-disable-next-line max-len */}
          Chinastraat werkt samen met een professioneel team van techniekers en cateraars. We werken graag samen met jou je evenement uit.
        </p>
      </article>

      <article className="bg-black text-white py-[10vw] px-[2vw]">
        <h2 className="huren-title text-left mb-[1vw]">Onze ruimtes</h2>
        <div className="grid lg:grid-cols-2 gap-y-[5vw] lg:gap-y-[3vw] gap-x-[3vw] ">
          {ruimtes.map(({ name, description }) => (
            <div className="flex flex-col items-start" key={name}>
              <h2 className="huren-subtitle">{name}</h2>
              {Array.isArray(description) ? (description.map((text) => <p className="text-left huren-place-text mb-[3vw]">{text}</p>))
                : <p className="text-left huren-place-text">{description}</p>}
            </div>
          ))}
          <div className="huren-place-text lg:col-span-2">
            <p>
              Samen met Stad Gent, Totum, Rave Alert, Roots of Minimal, Lost Theory, Moodfamily,
              Splén, Hip Hop Hooray, Democrazy, Maker Faire Gent, Vino Kilo, Music Mania Gent en
              veel meer kwamen hier al bruisende events tot stand. Clips van Stromae, Raketkanon,
              Deus en Oscar & The Wolf werden hier al opgenomen.
            </p>
            <p className="mt-[3vw]">
              Interesse in het huren van onze locatie?
              Vul dan het formulier in en we nemen contact met je op.
            </p>
          </div>
        </div>
      </article>

      <Carousel
        className="relative"
        autoPlay
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        renderArrowPrev={PreviousArrow}
        renderArrowNext={NextArrow}
      >
        <img src={realFirst} alt="realFirst" />
        <img src={realSecond} alt="realSecond" />
        <img src={first} alt="first" />
        <img src={second} alt="second" />
        <img src={third} alt="third" />
        <img src={fourth} alt="fourth" />
        <img src={seventh} alt="seventh" />
      </Carousel>

      <article className="pt-[9vw] huren-bg-svg">
        <div
          className={cx('bg-white black questions text-left px-[2.5vw] ')}
        >
          <p className=" my-[7vw] pt-[3vw]">
            Interesse in het huren van onze locatie?
            <br />
            Vul het formulier in en we nemen contact met je op.
          </p>

          <p className="flex flex-col items-end space-y-2 lg:space-y-8">
            <span>
              Scroll naar&nbsp;
              <button type="button" className="underline" onClick={scrollSmoothToForm}>formulier</button>
            </span>
          </p>
        </div>
      </article>

      <article ref={formSection} className="bg-theme color-theme black py-[7vw] px-[2.5vw] lg:px-[2vw]">
        <h3 className="huren-title ">Formulier</h3>
        {success && (
        <p className="bg-china-green text-black agenda-items px-[2.5vw] py-[2vw]">Je verzoek werd succesvol verzonden!</p>
        )}

        {error && (
        <p className="bg-red-500 text-black agenda-items px-[2.5vw] py-[2vw]">Gelieve alle verplichte velden in te vullen.</p>
        )}
        <form id="rental" name="rental" method="POST" action="/huren/?success=true" data-netlify="true" className="pt-[1.5vw] flex flex-col lg:flex-row justify-start items-start agenda-items-s lg:space-x-[3vw]">

          <div className="w-full lg:w-1/2 space-y-[3vw] lg:space-y-[1vw]">
            <input type="hidden" name="form-name" value="rental" />
            <label htmlFor="full-name" className="flex flex-col !mt-0">
              <span className="pb-[0.7vw]">Familienaam + Voornaam *</span>
              <input
                type="text"
                name="full-name"
                value={fullName}
                onChange={(e: any) => setName(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="email" className="flex flex-col">
              <span className="pb-[0.7vw]">E-mailadres *</span>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="phone-number" className="flex flex-col">
              <span className="pb-[0.7vw]">Telefoon *</span>
              <input
                type="text"
                name="phone-number"
                value={phoneNumber}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="organization" className="flex flex-col">
              <span className="pb-[0.7vw]">Organisatie</span>
              <input
                type="text"
                name="organization"
                value={organization}
                onChange={(e: any) => setOrganization(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="what" className="flex flex-col">
              <span className="pb-[0.7vw]">Wat wil je organiseren? (Leg kort uit) *</span>
              <input
                type="text"
                name="what"
                value={what}
                onChange={(e: any) => setWhat(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 mt-[3vw] lg:mt-0 space-y-[3vw] lg:space-y-[1vw]">
            <label htmlFor="desired-dates" className="flex flex-col">
              <span className="pb-[0.7vw]">Gewenste datum (meerdere opties geven aub) *</span>
              <input
                type="text"
                name="desired-dates"
                value={desiredDates}
                onChange={(e: any) => setDesiredDates(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="expected-attendance" className="flex flex-col">
              <span className="pb-[0.7vw]">Verwachte opkomst *</span>
              <input
                type="text"
                name="expected-attendance"
                value={expectedAttendance}
                onChange={(e: any) => setExpectedAttendance(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="entrance-fee" className="flex flex-col">
              <span className="pb-[0.7vw]">Inkomprijs *</span>
              <input
                type="text"
                name="entrance-fee"
                value={entranceFee}
                onChange={(e: any) => setEntranceFee(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="ideal-day" className="flex flex-col">
              <span className="pb-[0.7vw]">Schets je ideale dag (aankomstuur, activiteiten, ... )</span>
              <input
                type="text"
                name="ideal-day"
                value={idealDay}
                onChange={(e: any) => setIdealDay(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <label htmlFor="additional" className="flex flex-col">
              <span className="pb-[0.7vw]">Overige vragen, opmerkingen of info</span>
              <input
                type="text"
                name="additional"
                value={additional}
                onChange={(e: any) => setAdditional(e.target.value)}
                className="huren-input bg-transparent outline-0 border-l border-b border-white py-[0.4vw] pl-[1vw]"
              />
            </label>
            <div className="flex flex-row justify-between h-[14vw] lg:h-[7vw]">
              <p>* Verplicht veld</p>
              <button type="button" onClick={handleSubmit} className="self-end underline intro-text">Verzenden</button>
            </div>
          </div>
        </form>
      </article>
    </section>
  );
}
