import * as React from 'react';
import axios from 'axios';
import { PortableText } from '@portabletext/react';
import { DATASET, myPortableTextComponents, PROJECT_ID } from './Agenda';
import { cx } from '../utils';
import { VacaturesSvg } from '../assets/banners/desktop/VacaturesSvg';
import { ArrowIcon } from '../assets/banners/desktop/ArrowIcon';

const QUERY = '*[_type == "vacancy"] | order(_createdAt desc)';

const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

interface VacancyViewModel {
  jobTitle: string;
  jobDescription: any;
  profile: any;
  professionalCompetences: any;
  personalCompetences: any;
  offer: any;
  place: any;
}

function Vacancy({
  jobTitle,
  jobDescription,
  profile,
  professionalCompetences,
  personalCompetences,
  offer,
  place,
}: VacancyViewModel): JSX.Element {
  const [vacancyIsOpen, setVacancyIsOpen] = React.useState<boolean>(false);
  const vacancyInfoRef = React.useRef<HTMLDivElement>(null);

  const openVacancy = () => setVacancyIsOpen(!vacancyIsOpen);

  return (
    <div className="grid grid-cols-12 items-start border-b-2 border-b-black  agenda-items-padding">
      <button
        className="col-span-full flex justify-between items-center gap-x-[5vw]"
        type="button"
        onClick={openVacancy}
      >
        <h2 className="vacancy-title font-bold">{jobTitle}</h2>
        <span className={cx(
          'h-[3vw] lg:h-[2vw]  lg:hover:cursor-pointer lg:hover:underline vacancy-title !font-medium text-right transform transition transform',
          { 'rotate-180': vacancyIsOpen },
        )}
        >
          <ArrowIcon />
        </span>

      </button>
      <div
        style={(vacancyIsOpen && vacancyInfoRef && vacancyInfoRef.current)
          ? { maxHeight: vacancyInfoRef.current.clientHeight }
          : { maxHeight: 0 }}
        className={cx('col-span-full overflow-hidden transition-all duration-500 text-left')}
      >
        <div ref={vacancyInfoRef} className="py-[2vw] vacancy-text space-y-[1vw]">
          <PortableText value={jobDescription} components={myPortableTextComponents} />
          <h2 className="vacancy-subtitle !mt-[3vw]">Profiel</h2>
          <PortableText value={profile} components={myPortableTextComponents} />
          <h3 className="vacancy-small-subtitle !mt-[2vw]">Jobgerelateerde competenties</h3>
          <PortableText value={professionalCompetences} components={myPortableTextComponents} />
          <h3 className="vacancy-small-subtitle !mt-[2vw]">Persoonlijke competenties</h3>
          <PortableText value={personalCompetences} components={myPortableTextComponents} />
          <h2 className="vacancy-subtitle !mt-[3vw]">Aanbod</h2>
          <PortableText value={offer} components={myPortableTextComponents} />
          <h2 className="vacancy-subtitle !mt-[3vw]">Hoe solliciteren?</h2>
          <PortableText value={place} components={myPortableTextComponents} />
        </div>
      </div>
    </div>

  );
}

function Vacancies(): JSX.Element {
  const [vacancies, setVacancies] = React.useState<[]>([]);

  React.useEffect(() => {
    async function fetchVacancies() {
      try {
        const { data: { result } } = await axios.get(URL);
        setVacancies(result);
      } catch (e) {
        console.error(e);
      }
    }
    fetchVacancies();
  }, []);

  return (
    <section>
      <h1 className="h-auto lg:h-[calc(1080 / 1920 * 100vw)] w-screen flex items-end ">
        <VacaturesSvg />
      </h1>
      <div className="mt-[4vw] px-[2.5vw] mb-[4vw]">

        {vacancies && vacancies.length > 0 && vacancies.map(({
          _id,
          jobTitle,
          jobDescription,
          profile,
          professionalCompetences,
          personalCompetences,
          offer,
          place,
        }) => (
          <Vacancy
            key={_id}
            jobTitle={jobTitle}
            jobDescription={jobDescription}
            profile={profile}
            professionalCompetences={professionalCompetences}
            personalCompetences={personalCompetences}
            offer={offer}
            place={place}
          />
        ))}
      </div>
    </section>
  );
}

export default Vacancies;
