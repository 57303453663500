import * as React from 'react';
import {
  NavLink, Outlet, useLocation,
} from 'react-router-dom';
import { useContext, useState } from 'react';
import * as ReactGA from 'react-ga';
import { Footer } from './Footer';
import { ThemeContext } from '../App';
import { NewsBanner } from '../components/NewsBanner';
import { cx } from '../utils';

export function Layout() {
  const theme = useContext(ThemeContext);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname !== '/huren/') window.scrollTo(0, 0);
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  React.useEffect(() => {
    if (menuIsOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [menuIsOpen]);

  return (
    <div className={cx({ 'overflow-hidden': menuIsOpen })}>
      <header>
        <nav className={`bg-black transition-all duration-300 overflow-hidden w-screen h-screen z-50 fixed ${!menuIsOpen ? ' translate-y-[-100vh]' : 'translate-y-0'}`}>
          <button
            type="button"
            name="close-menu"
            onClick={() => {
              setMenuIsOpen(false);
            }}
            className={`z-10 fixed right-[2.5vw] lg:right-[1.2vw] w-[4vw] lg:w-[2.5vw] ${!menuIsOpen ? ' -top-[100vh]' : 'top-[1.5vw] lg:top-[1.5vw]'}`}
          >
            <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <span aria-label="close menu" />
              <rect
                x="6.23999"
                width="42.0869"
                height="8.82467"
                transform="rotate(45 6.23999 0)"
                fill={theme === 'black' || pathname === '/club' ? '#fff' : '#000'}
              />
              <rect
                x="36"
                y="6.23997"
                width="42.0869"
                height="8.82467"
                transform="rotate(135 36 6.23997)"
                fill={theme === 'black' || pathname === '/club' ? '#fff' : '#000'}
              />
            </svg>
          </button>
          <ul className={cx('list-none h-full flex flex-col items-start justify-start lg:justify-between bg-theme', theme)}>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/"
                className="w-full"
                onClick={() => setMenuIsOpen(false)}
              >
                HOME
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/agenda"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                AGENDA
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/bar-bricolage"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                BAR BRICOLAGE
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/club"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                CLUB
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/expo-ruimte"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                EXPO
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/makers"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                MAKERSPACE
              </NavLink>
            </li>
            <li className={`nav-title ${theme}`}>
              <NavLink
                to="/info"
                className="w-full"
                onClick={() => {
                  setMenuIsOpen(false);
                }}
              >
                INFO
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="relative z-10">
          <button
            type="button"
            name="open-menu"
            onClick={() => {
              setMenuIsOpen(true);
            }}
            className="z-40 fixed right-[2.8vw] top-[2.8vw] lg:right-[1.7vw] lg:top-[1.6vw] w-[6vw] sm:w-[5vw] lg:w-[3vw]"
          >
            <span aria-label="open menu" />
            <svg width="100%" height="100%" viewBox="0 0 814 530" fill="none">
              <rect
                x="0.453125"
                y="0.288574"
                width="813.022"
                height="121.011"
                fill={pathname !== '/club' ? 'black' : 'white'}
              />
              <rect
                x="0.453125"
                y="204.495"
                width="813.022"
                height="121.011"
                fill={pathname !== '/club' ? 'black' : 'white'}
              />
              <rect
                x="0.453125"
                y="408.701"
                width="813.022"
                height="121.011"
                fill={pathname !== '/club' ? 'black' : 'white'}
              />
            </svg>
          </button>
        </div>
      </header>
      <main>
        <Outlet />
      </main>

      <Footer />

      {pathname === '/' && <NewsBanner announcement="NIEUWS" />}
    </div>
  );
}
