import * as React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { Layout } from './containers/Layout';

import { Chinastraat } from './pages/Chinastraat';
import Agenda from './pages/Agenda';
import { BarBricolage } from './pages/BarBricolage';
import { Club } from './pages/Club';
import { ExpoRuimte } from './pages/ExpoRuimte';
import { Makers } from './pages/Makers';
import { Info } from './pages/Info';
import { Huisregels } from './pages/Huisregels';
import { Medialab } from './pages/Medialab';
import { Huren } from './pages/Huren';
import Vacancies from './pages/Vacancies';
import Faqs from './pages/Faqs';

const themes = ['green', 'blue', 'yellow'] as const;

export const ThemeContext = React.createContext<'green' | 'blue' | 'yellow'>(themes[Math.floor(Math.random() * 3)]);

const TRACKING_ID = 'UA-74610630-2';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <ThemeContext.Provider value={themes[Math.floor(Math.random() * 3)]}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Chinastraat />} />
            <Route path="agenda" element={<Agenda />} />
            <Route path="bar-bricolage" element={<BarBricolage />} />
            <Route path="club" element={<Club />} />
            <Route path="expo-ruimte" element={<ExpoRuimte />} />
            <Route path="makers" element={<Makers />} />
            <Route path="info" element={<Info />} />
            <Route path="vacatures" element={<Vacancies />} />

            <Route path="huren" element={<Huren />} />
            <Route path="faq" element={<Faqs />} />
            <Route path="huisregels" element={<Huisregels />} />
            <Route path="medialab" element={<Medialab />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}

export default App;
