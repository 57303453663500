import * as React from 'react';
import { cx } from '../utils';

export function Medialab() {
  return (
    <section className="w-screen">
      <h1 className="bg-svg medialab ">
                &nbsp;
      </h1>

      <article className={cx('intro-text text-left flex flex-col lg:items-end lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[11vw] lg:mt-0 ')}>
        <p className=" lg:w-2/3">
          {/* eslint-disable-next-line max-len */}
          MediaLab is de creatieve tak van&nbsp;
          <a className="underline" href="https://future-media.be" target="_blank" rel="noreferrer">Future Media</a>
            &nbsp;(het bedrijf achter Chinastraat). Ze creëren unieke festival
          decors maken door middel van parametrisch design, laser cutting en pixelmapping.
        </p>
        <br />
        <p className="lg:w-2/3 self-start lg:self-auto">
          meer info —&nbsp;
          <a className="underline" href="https://media-lab.be/" target="_blank" rel="noreferrer">media-lab.be</a>
        </p>
      </article>
    </section>
  );
}
