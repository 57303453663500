import * as React from 'react';
import { Link } from 'react-router-dom';
import { InfoSvg } from '../assets/banners/desktop/InfoSvg';
import { cx } from '../utils';
import { ThemeContext } from '../App';

export function Info() {
  const theme = React.useContext(ThemeContext);

  return (
    <section className="w-screen">
      <div className=" lg:hidden bg-svg info">
            &nbsp;
      </div>
      <div className="bg-svg hidden lg:block -mb-24"><InfoSvg /></div>

      <div className={cx('intro-text bg-theme-m color-theme-m text-left flex flex-col lg:items-end lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[10vw] lg:mt-0 -mb-2 space-y-[10vw] lg:space-y-[5vw]', theme)}>
        <p className=" lg:w-2/3">
          {/* eslint-disable-next-line max-len */}
          Chinastraat is een centrum voor stadscultuur, een unieke evenementenlocatie en een inspirerende ontmoetingsplek voor jong en jong van geest. Chinastraat is ook een toevluchtsoord en platform voor creatie en kruisbestuiving. Een plek waar wordt gecreëerd en geëxperimenteerd.
        </p>
        <p className=" lg:w-2/3">
          {/* eslint-disable-next-line max-len */}
          Bar Bricolage, de bar van Chinastraat, is een groene oase en de gezelligste bar van Gent. In de exporuimte huist Ontsteking: een expo- en projectruimte voor actuele kunst, gerund door collectief NADAR. In de makerspaces en repetitieruimtes resideert een creatief gezelschap uit diverse sectoren - beeldende kunsten, muziek, ambachten.
        </p>
        <p className=" lg:w-2/3">
          Chinastraat.
        </p>
        <p className=" lg:w-2/3">Even weg van de wereld.</p>
      </div>

      <div className={cx('bg-theme-m color-theme-m intro-text text-left grid grid-cols-1 lg:grid-cols-2 gap-x-6 lg:py-[4vw] px-[2.5vw] lg:px-[2vw] mt-[15vw] lg:mt-0', theme)}>
        <div className="space-y-[15vw] lg:space-y-10">
          <div>
            <h1 className="intro-text-title mb-2">
              Locatie
            </h1>
            <p>
              De Chinastraat is een oude hondenvoederfabriek.
              Het ligt in de Oude Gentse Dokken, vlakbij het Kapitein
              Zeppospark.
            </p>
          </div>

          <div>
            <h1 className="intro-text-title mb-2">
              Openingsuren
            </h1>
            { new Date().getMonth() > 2 && new Date().getMonth() < 10 ? (
              <p>
                Bar Bricolage is open van vrijdag 5 april tem zondag 15 september 2024.
                Van dinsdag tem vrijdag vanaf 16u,
                op zaterdag vanaf 14u en
                op zondag vanaf 11u.
                (Onder voorbehoud en bij slecht weer kunnen de openingsuren veranderen.)
              </p>
            )
              : (
                <p>
                  Tijdens de wintermaanden blijft de
                  club en concertzaal
                  {' '}
                  <Link to="/" className="underline">
                    Chinastraat
                  </Link>
                  {' '}
                  draaien,
                  de buitenbar,
                  {' '}
                  <Link to="/bar-bricolage" className="underline">Bar Bricolage</Link>
                  , zal enkel tijdens evenementen open zijn. Zonder vaste openingsuren,
                  mét wekelijkse club nights, concerten, markten en meer.
                </p>
              )}
          </div>
        </div>

        <div className="mt-[15vw] lg:mt-0 mb-[15vw] lg:mb-[5vw]">
          <h1 className="intro-text-title mb-2">
            Bereikbaarheid
          </h1>
          <div className="mb-8">
            <h2>Fiets</h2>
            <p>Tien minuutjes fietsen vanuit het centrum Gent</p>
          </div>
          <div className="mb-8">
            <h2>Bus</h2>
            <p>
              Neem vanaf Gent Dampoort bus 6 en stap uit bij halte Gent Aziëstraat.
              Neem vanaf Gent-Sint-Pieters tram 4 richting UZ - Ledeberg - Stelplaats
              en stap uit bij halte Gent Tolpoort
            </p>
          </div>
          <div>
            <h2>Auto</h2>
            <p>Houd rekening met een beperkte parkeermogelijkheid</p>
          </div>
        </div>
      </div>

      <div className="info-bg-svg">
        <div
          className={cx('h-[10vw] z-20 linear-gradient lg:hidden', theme)}
        >
          &nbsp;
        </div>
        <div className="lg:pt-[11vw] lg:pb-[2vw]">
          <div
            className={cx('bg-theme-d color-theme-d questions text-left px-[2.5vw] lg:px-[2vw] py-[15vw] lg:py-[6.35vw] ', theme)}
          >
            <p className="my-[1vw] lg:my-[2vw]">
              <Link to="/bar-bricolage" className="underline">Bar Bricolage</Link>
              <br />
              Heb je een vraag of een idee voor Bar Bricolage?
              <br />
              Heb je een idee maar geen plaats om het uit te voeren?
            </p>
            <p className="flex flex-col items-end space-y-[0.5vw] lg:space-y-[2vw]">
              <span>
                Mail naar&nbsp;
                <a href="mailto:info@bar-bricolage.be" className="underline ">
                  info@bar-bricolage.be.
                </a>
              </span>
            </p>
          </div>
        </div>

        <div
          className={cx('h-[10vw] z-20 linear-gradient hidden lg:block lg:mt-6', theme)}
        >
          &nbsp;
        </div>
        <div
          className={cx('bg-theme-m color-theme-m questions text-left px-[2.5vw] lg:px-[2vw]  py-[15vw] lg:py-[15vw]', theme)}
        >
          <p className="my-[1vw] lg:my-[2vw]">
            <Link to="/huren" className="underline">Huren</Link>
            <br />
            Heb je een vraag in verband met het huren van één van onze polyvalente zalen?
            <br />
            Ben je op zoek naar interessante samenwerkingen?
          </p>

          <p className="flex flex-col items-end space-y-2 lg:space-y-8">
            <span>
              Mail naar&nbsp;
              <a href="mailto:info@chinastraat.be" className="underline ">
                info@chinastraat.be
              </a>
            </span>
          </p>
        </div>

        <div
          className={cx('h-[10vw] z-20 linear-gradient lg:hidden', theme)}
        >
          &nbsp;
        </div>
        <div
          className={cx('bg-theme-d color-theme-m questions text-left px-[2.5vw] lg:px-[2vw] py-12 py-[15vw] lg:py-[14vw] mb-8', theme)}
        >
          <p className="my-[1vw] lg:my-[2vw]">
            <Link to="/expo-ruimte" className="underline">Exporuimte</Link>
            <br />
            Heb je een vraag over de exporuimte en de werking van Ontsteking?
          </p>

          <p className="flex flex-col items-end space-y-[1vw] lg:space-y-[2vw]">
            <span>
              Mail naar&nbsp;
              <a href="mailto:info@ontsteking.org" className="underline ">
                info@ontsteking.org
              </a>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
