import * as React from 'react';
import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ThemeContext } from '../App';
import { cx } from '../utils';
import CustomForm from '../components/CustomForm';

export function Footer() {
  const theme = useContext<'green' | 'blue' | 'yellow'>(ThemeContext);
  const { pathname } = useLocation();
  const [themeException, setThemeException] = React.useState<'green' | 'blue' | 'yellow' | 'white-blue' | 'black' | 'full-black'>(theme);

  React.useEffect(() => {
    if (pathname === '/bar-bricolage' || pathname === '/bar-bricolage/') {
      setThemeException('white-blue');
    } else if (pathname === '/club'
        || pathname === '/huren'
        || pathname === '/medialab'
        || pathname === '/huisregels'
        || pathname === '/faq'
        || pathname === '/huren/') {
      setThemeException('black');
    } else {
      setThemeException(theme);
    }
  }, [pathname]);

  return (
    <footer>
      {(pathname !== '/bar-bricolage'
          && pathname !== '/club'
          && pathname !== '/huren'
          && pathname !== '/medialab'
          && pathname !== '/huisregels'
          && pathname !== '/faq'
          && pathname !== '/huren/') && (
          <div
            className={cx('h-[15vh] z-20 linear-gradient', themeException, { 'full-black': pathname === '/club' })}
          >
          &nbsp;
          </div>
      )}
      {(
        pathname === '/medialab'
      || pathname === '/huisregels'
      || pathname === '/faq'
      ) && (
      <div
        className={cx('h-[15vh] lg:hidden z-20 linear-gradient black')}
      >
            &nbsp;
      </div>
      )}
      <div className={cx('flex flex-col lg:flex-row text-left pb-[10%] pt-10 px-[2.5vw] footer space-y-12 lg:space-y-0', themeException)}>
        <div className="w-full lg:w-1/2 footer-text-l space-y-2">
          <a
            href="https://www.google.com/maps/place/De+Chinastraat/@51.0728387,3.7309545,17z/data=!3m1!4b1!4m5!3m4!1s0x47c371b6ec503be5:0xb194aff3f37b6329!8m2!3d51.0728354!4d3.7354392"
            target="_blank"
            rel="noreferrer"
            className="underline lg:no-underline lg:hover:underline"
          >
            <span className="mb-2">Chinastraat 1</span>
            <br />
            <span className="mb-2">9000 Gent</span>
          </a>
          <br />

          <a
            href="mailto:info@chinastraat.be"
            className="underline lg:no-underline lg:hover:underline"
          >
            info@chinastraat.be
          </a>
        </div>

        <div className="w-full lg:w-1/2 flex footer-text">
          <div className="flex flex-wrap w-full">
            <CustomForm theme={themeException} />
            <div className="w-1/2 lg:w-2/3">
              <nav className="grid lg:grid-cols-2 gap-y-2">
                <NavLink to="/huren" className="underline lg:no-underline lg:hover:underline">Huren</NavLink>

                <NavLink to="/faq" className="underline lg:no-underline lg:hover:underline ">
                  FAQ
                </NavLink>

                <NavLink
                  to="/medialab"
                  className="underline lg:no-underline lg:hover:underline w-full"
                >
                  Medialab
                </NavLink>
              </nav>
            </div>
            <div className="w-1/2 lg:w-1/3">
              <nav className="space-y-2 h-full flex flex-col lg:justify-start">
                <a
                  href="https://www.instagram.com/chinastraat/"
                  target="_blank"
                  className="underline lg:no-underline lg:hover:underline"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.facebook.com/chinastraat"
                  target="_blank"
                  className="underline lg:no-underline lg:hover:underline"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
