import * as React from 'react';
import { useContext } from 'react';
import { colorDict } from './ExpoSvg';
import { ThemeContext } from '../../../App';

export function InfoSvg() {
  const theme = useContext(ThemeContext);

  return (
    <svg width="100%" height="100%" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="1080" fill="white" />
      <path d="M1577.18 902.28V857.11H1547.85V902.28C1547.81 934.794 1534.88 965.965 1511.89 988.955C1488.9 1011.94 1457.72 1024.88 1425.21 1024.91H1380.05V1054.25H1745V1024.91H1699.85C1667.33 1024.88 1636.15 1011.95 1613.16 988.963C1590.16 965.973 1577.22 934.798 1577.18 902.28V902.28Z" fill="black" />
      <path d="M1849.19 336.5C1819.63 336.468 1791.28 324.712 1770.37 303.809C1749.46 282.907 1737.7 254.565 1737.66 225V183.91H1405.82V225C1405.78 254.564 1394.02 282.906 1373.11 303.809C1352.21 324.711 1323.86 336.468 1294.3 336.5H1086.3C1056.73 336.476 1028.38 324.724 1007.46 303.82C986.543 282.917 974.772 254.571 974.73 225V176.85V168.25C975.098 138.903 987.007 110.88 1007.88 90.2472C1028.75 69.614 1056.91 58.0293 1086.26 58.0001H1127.32V31.3301H975H338V72.3501C337.963 101.917 326.202 130.262 305.296 151.169C284.391 172.077 256.047 183.84 226.48 183.88H185.42V215.4C185.383 244.968 173.621 273.315 152.713 294.223C131.805 315.131 103.458 326.893 73.8901 326.93H32.8901V666.93H212.09V641.63H171C141.438 641.59 113.099 629.827 92.1981 608.921C71.2974 588.015 59.5419 559.672 59.5101 530.11V474.66C59.5392 445.096 71.2935 416.751 92.1945 395.843C113.096 374.934 141.436 363.17 171 363.13H212.07V322.07C212.102 292.499 223.864 264.149 244.776 243.242C265.687 222.334 294.039 210.577 323.61 210.55H364.67V169.48C364.713 139.918 376.478 111.58 397.386 90.6809C418.295 69.7821 446.638 58.0292 476.2 58.0001H836.81C865.049 58.0319 892.227 68.7619 912.871 88.0294C933.515 107.297 946.092 133.671 948.07 161.84V363.17H1432.49V322.11C1432.52 292.545 1444.28 264.201 1465.19 243.295C1486.09 222.389 1514.44 210.627 1544 210.59H1599.46C1629.03 210.617 1657.38 222.374 1678.29 243.282C1699.21 264.189 1710.97 292.539 1711 322.11V363.17H1752.08C1781.65 363.205 1809.99 374.965 1830.9 395.872C1851.8 416.779 1863.56 445.125 1863.59 474.69V530.15C1863.56 559.716 1851.8 588.061 1830.9 608.968C1809.99 629.875 1781.65 641.636 1752.08 641.67H1711V666.89H1890.27V336.5H1849.19Z" fill="black" />
      <rect y="806.759" width="1920" height="273.241" fill="url(#paint0_linear_963_113)" />
      <path d="M69.5199 830.79V1052.43H32.6299V830.79H69.5199Z" fill="black" />
      <path d="M103 830.79H144.23L244 997.25H244.62V830.79H281.51V1052.43H240.63L140.5 886.58H139.88V1052.43H103V830.79Z" fill="black" />
      <path d="M447.4 926.57V958.19H351.61V1052.43H314.72V830.79H469.72V862.09H351.61V926.57H447.4Z" fill="black" />
      <path d="M477.17 941.45C477.17 874.81 519.02 826.14 585.97 826.14C652.62 826.14 694.78 874.81 694.78 941.45C694.78 1008.09 652.62 1057.08 586 1057.08C519 1057.08 477.17 1008.1 477.17 941.45ZM657.89 941.45C657.89 896.82 636.81 857.75 585.97 857.75C535.13 857.75 513.75 896.82 513.75 941.45C513.75 986.45 535.13 1025.45 585.97 1025.45C636.81 1025.45 657.89 986.41 657.89 941.45Z" fill="black" />
      <defs>
        <linearGradient id="paint0_linear_963_113" x1="960" y1="806.759" x2="960" y2="1080" gradientUnits="userSpaceOnUse">
          <stop stopColor={colorDict[theme]} stopOpacity="0" />
          <stop offset="1" stopColor={colorDict[theme]} />
        </linearGradient>
      </defs>
    </svg>
  );
}
