import * as React from 'react';
import { cx } from '../utils';

const huisregels = [
  {
    title: 'TOEGANGSCONTROLE',
    text: 'Als bezoeker kan je gefouilleerd en/of gecontroleerd worden op drank-, drugs- en wapenbezit. '
        + 'Werk je niet mee aan deze controle, dan kunnen we beslissen om je de'
        + ' toegang tot De Chinastraat te ontzeggen.',
  },
  {
    title: 'LEEFTIJD',
    text: 'Bij de meeste nightlife evenementen moeten de bezoekers minstens 18 jaar oud zijn. '
        + 'Personen jonger dan 18 krijgen geen toegang tot De Chinastraat. Plan je een bezoek aan De Chinastraat,'
        + ' vergeet dan je identiteitsbewijs niet mee te nemen. Enkel bezoekers die een geldig identiteitsbewijs '
        + '(identiteitskaart of paspoort) bij zich hebben, krijgen toegang tot De Chinastraat.',
  }, {
    title: 'DRUGS',
    text: 'Personen die in het bezit en/of onder invloed zijn van soft- of harddrugs zijn niet welkom en worden'
        + ' geweigerd. We willen de gezondheid van onze bezoekers niet in gevaar brengen, daarom is '
        + 'drugsgebruik ten strengste verboden. Dit zowel binnen als buiten de muren van De Chinastraat. '
        + 'In De Chinastraat geldt een zero-tolerancebeleid waarbij geen enkel excuus wordt aanvaard.',
  }, {
    title: 'AANSTOOTGEVEND GEDRAG',
    text: 'Personen die geweld plegen of uitlokken t.o.v. andere bezoekers, de uitbater of zijn personeel, '
        + 'worden geweigerd. Hetzelfde geldt voor personen die wapens of gevaarlijke voorwerpen '
        + 'bij zich hebben en/of gebruiken.',
  }, {
    title: 'DRESSCODE',
    text: 'T-shirts of kledij met kwetsende slogans zijn niet welkom voor de rest is er in'
        + ' De Chinastraat geen dresscode en mag je lekker jezelf zijn.',
  }, {
    title: 'RACISME, DISCRIMINATIE EN INTIMIDATIE',
    text: 'Een bezoek aan De Chinastraat moet voor iedereen een fijne ervaring zijn. '
        + 'Daarom is elke vorm van racisme, discriminatie en intimidatie strikt verboden. '
        + 'Daartoe behoren onder meer: racistische, fascistische, seksistische, beledigende en'
        + ' discriminerende uitlatingen, ongewenste intimiteiten, hinderlijk en agressief gedrag, '
        + 'vandalisme en geweld. Ook personen die zich gedragen alsof ze uit zijn op het plegen van'
        + ' geweld, zullen geen toegang krijgen.',
  }, {
    title: 'EIGENDOM VAN DE CHINASTRAAT',
    text: 'Wie geen respect toont voor het in- en exterieur van De Chinastraat riskeert een sanctie.'
        + ' Beschadig je eigendom van De Chinastraat, dan ben je verantwoordelijk '
        + 'voor de reparatie of het vernieuwen ervan.',
  }, {
    title: 'HUISREGELS VERSTOREN VAN DE SFEER',
    text: 'De Chinastraat staat bekend om zijn goede en gemoedelijke sfeer. Dat willen we natuurlijk graag zo houden.'
        + ' Wie overdreven lawaaierig is of de sfeer verstoort, riskeert een sanctie. '
        + 'Personen die in het verleden moeilijkheden hebben veroorzaakt in De Chinastraat of'
        + ' andere horecagelegenheden, worden niet toegelaten.',
  }, {
    title: 'GEBRUIK VAN DE TOILETTEN',
    text: 'Het is niet toegestaan om met meerdere personen één toiletruimte te gebruiken.',
  }, {
    title: 'BEVEILIGINGSCAMERA’S',
    text: 'Binnen en buiten gebruiken we op verschillende plaatsen beveiligingsapparatuur zoals camera’s. '
        + 'Als bezoeker van De Chinastraat ga je ermee akkoord dat je gefilmd en/of gefotografeerd wordt. '
        + ' te gebruiken en door te geven. Je doet hierbij afstand van het recht op compensatie. De opnames'
        + ' kunnen bij ongevallen, geweldplegingen of noodsituaties aan politie en/of derden worden getoond.',
  }, {
    title: 'ROKEN',
    text: 'Roken is enkel toegestaan buiten de gebouwen in de tuin.',
  }, {
    title: 'FOTO’S NEMEN OF FILMEN',
    text: 'Wil je in De Chinastraat foto’s nemen, video- of geluidsopnames maken, dan moet je reeds'
        + ' vooraf over een schriftelijke toestemming beschikken. Zonder deze toestemming is het niet'
        + ' toegestaan om binnen De Chinastraat opnames te maken. Onrechtmatig verkregen opnames worden'
        + ' in beslag genomen.',
  }, {
    title: 'PROMOTIE',
    text: 'Wil je promotieactiviteiten voeren binnen of buiten De Chinastraat, dan moet je dit ruim van'
        + ' tevoren aanvragen. Zonder schriftelijke bevestiging is het niet toegestaan om promotie te '
        + 'voeren, dit zowel binnen als buiten De Chinastraat. Promotieactiviteiten omvatten onder meer het'
        + ' uitdelen van flyers, affiches of ander promotiemateriaal.',
  },
  {
    title: 'CONSUMPTIE VAN DRANKEN',
    text: 'Je mag consumpties enkel binnen of op het terrein van De Chinastraat gebruiken. '
        + 'Het is verboden om consumpties, glazen of flessen mee naar binnen of buiten te nemen.',
  },
  {
    title: 'VERKOOP VAN GOEDEREN',
    text: 'Je mag binnen De Chinastraat geen goederen verkopen of verhandelen zonder schriftelijke toestemming.',
  },
  {
    title: 'INSTRUCTIES VAN PERSONEEL',
    text: 'Als bezoeker moet je instructies van de directie en/of personeelsleden meteen opvolgen, '
        + 'dit in het belang van je eigen veiligheid.',
  },
  {
    title: 'BIJ HET VERLATEN',
    text: 'We vragen je om bij het verlaten van De Chinastraat:',
    list: [
      'Geen vandalisme aan te richten',
      'Geen geluidsoverlast te veroorzaken',
      'De orde en netheid te respecteren',
      'Geen voertuig te besturen indien je alcohol hebt gedronken',
      'Respect te tonen voor de omliggende woningen en hun bewoners',

    ],
  },
  {
    title: 'BIJ EEN OVERTREDING',
    list: [
      'Overtreed je de huisregels, dan word je verwijderd uit de zaak. Je entree- en parkinggeld wordt niet terugbetaald.'
      + ' Dit gaat ook op wanneer je een overtreding uitlokt of iemand ertoe aanzet. Je krijgt een voorlopig'
      + ' lokaalverbod, dat van kracht blijft tot de eerstvolgende bijeenkomst van het managementteam. '
      + 'Dit team bepaalt de uiteindelijke sanctie. Krijg je een definitief lokaalverbod, dan wordt dit'
      + ' gemeld bij de politie. Overtreed je het lokaalverbod, dan wordt dit aangegeven. Je riskeert de '
      + 'uitzetting door de politie, een bijbehorende gerechtelijke procedure en een permanente weigering.\n',
      'In geval van strafbare feiten, doen we steeds aangifte bij de politie. Mogelijk geven we '
      + 'ook een signalement door.',
      'Voor ongepaste zaken die niet beschreven staan in dit huisreglement, beslist de directie over de '
      + 'maatregelen die genomen zullen worden.\n',
    ],
  },
  {
    title: 'Aansprakelijkheid',
    text: 'De Chinastraat kan niet aansprakelijk gesteld worden voor letsels, materiële en/of immateriële schade aan '
        + 'bezoekers bij het betreden van de zaak. Het verblijf in De\n'
        + 'Chinastraat is geheel op eigen risico. We zijn ook niet aansprakelijk voor schade, diefstal '
        + 'of verlies van items (of de inhoud ervan).',
  },
  {
    title: 'Schenken van alcohol',
    text: 'Het is bij wet verboden om alcohol te schenken aan:',
    list: [
      '16 jarigen, en sterke drank',
      '18 jarigen. Bij twijfel kan het barpersoneel bewijs van leeftijd vragen',
    ],
  },
  {
    title: 'Klachten',
    text: 'Eventuele klachten kan je schriftelijk melden. Richt ze tot De Chinastraat (Future Media BV, Oude '
        + 'Heerweg Heide 105, 9250 Waasmunster), Stel dit niet uit, want drie werkdagen na het ontstaan van je'
        + ' klacht vervalt je ‘klachtrecht’.',
  },

];

interface HuisregelProps {
    title: string;
    text?: string;
    list?: string[];
}

function Huisregel({ title, text, list }: HuisregelProps) {
  const [textIsOpen, setTextIsOpen] = React.useState(false);
  const textRef = React.useRef(null);

  return (
    <article className="flex flex-col items-start">
      <button type="button" className="border-b-2 border-b-black  pt-[3vw] pb-[1vw] lg:pt-[1.5vw] lg:pb-[0.5vw] w-full" onClick={() => setTextIsOpen(!textIsOpen)}>
        <h2 className="text-left intro-text">
          {title.charAt(0).toUpperCase()
              + title.slice(1).toLowerCase()}
        </h2>
      </button>
      <div
        style={
          (textIsOpen && textRef && textRef.current)
            ? { maxHeight: textRef.current.clientHeight }
            : { maxHeight: 0 }
      }
        className="overflow-hidden transition-all duration-300"
      >
        <div ref={textRef} className="flex flex-col items-start intro-text">
          {text && <p className="text-left  py-1">{text}</p>}
          {!!list && (
          <ul className="ml-3">
            {list.map((listItem) => (
              <li key={listItem} className="huisregels-list-item text-left">{listItem}</li>
            ))}
          </ul>
          )}
        </div>
      </div>
    </article>
  );
}

export function Huisregels() {
  return (
    <section className="w-screen">
      <div className="bg-svg huisregels">
                &nbsp;
      </div>

      <div className={cx('bg-theme-m color-theme-m black intro-text text-left flex flex-col lg:items-end lg:py-[10vw] px-[2.5vw] lg:px-[2vw] -mt-[10vw] lg:mt-0')}>
        <p className="lg:w-2/3">
          Jouw veiligheid is erg belangrijk voor ons.
          Daarom stelden we huisregels op die gelden voor alle bezoekers van De Chinastraat.
          We gaan ervan uit dat je deze regels kent en naleeft.
          Weet dat het niet naleven ervan kan leiden tot een sanctie.
        </p>
      </div>

      <div className="px-[2.5vw] lg:px-[2vw] py-[8vw] lg:py-[5vw]">
        {huisregels.map(({ title, text, list }) => (
          <Huisregel
            key={title}
            title={title}
            text={text}
            list={list}
          />
        ))}
      </div>
    </section>
  );
}
