import * as React from 'react';
import axios from 'axios';
import { PortableText, PortableTextComponents } from '@portabletext/react';
import { cx } from '../utils';
import { AgendaSvg } from '../assets/banners/desktop/AgendaSvg';

const daysOfWeek = ['zon', 'ma', 'di', 'wo', 'don', 'vr', 'zat'];

export const myPortableTextComponents: PortableTextComponents = {
  marks: {
    link: ({ children, value }) => {
      const rel = value.href && !value.href.startsWith('/') ? 'noreferrer noopener' : undefined;
      return (
        <a href={value.href} className="underline" target="_black" rel={rel}>
          {children}
        </a>
      );
    },
  },
  list: ({ children }) => (<ul className="list-disc list-inside">{children}</ul>),
};

interface AgendaItemProps {
    name?: string;
    hasBeginAndEndDate?: boolean;
    startDate?: Date |string;
    endDate?: Date |string;
    date?: Date | string;
    type?: 'club' | 'concert' | 'dj' | 'bar_bricolage' | 'markt' | 'expo';
    info?: any;
    eventUrl?: string;
    ticketsUrl?: string;
    ticketStatus?: 'free' | 'tickets' | 'sold_out' | 'reserveren' | 'inschrijven'
}

function AgendaItem({
  name = '',
  hasBeginAndEndDate = false,
  startDate = '',
  endDate = '',
  date = '',
  type = 'dj',
  info = '',
  eventUrl = '',
  ticketsUrl = 'https://www.facebook.com/chinastraat/events',
  ticketStatus = 'tickets',
}: AgendaItemProps): JSX.Element {
  const dayOfWeek = daysOfWeek[new Date(date).getDay()];

  const parsedStartDate = new Date(startDate).toLocaleDateString('nl-BE', { year: undefined, month: '2-digit', day: '2-digit' });
  const parsedEndDate = new Date(endDate).toLocaleDateString('nl-BE', { year: undefined, month: '2-digit', day: '2-digit' });
  const parsedDate = new Date(date).toLocaleDateString('nl-BE', { year: undefined, month: '2-digit', day: '2-digit' });

  const [infoIsOpen, setInfoIsOpen] = React.useState(false);

  const infoRefMobile = React.useRef(null);
  const infoRefDesktop = React.useRef(null);

  return (
    <article>
      <div className="lg:hidden grid grid-cols-24 items-start border-b-2 border-b-black agenda-items agenda-items-padding">
        <div className="col-span-7 agenda-items font-medium text-left flex flex-col leading-none">
          <time>{type.toLocaleUpperCase().replace('_', ' ')}</time>
          {hasBeginAndEndDate ? (
            <div className="flex">
              <time>{`${parsedStartDate}`}</time>
              &nbsp;-&nbsp;
              <time>{`${parsedEndDate}`}</time>
            </div>
          )
            : <time>{`${parsedDate}`}</time>}
        </div>
        <div className="col-span-11 font-medium text-left">
          {name}
        </div>
        <div className="col-span-6 flex flex-col">
          <button
            type="button"
            onClick={() => setInfoIsOpen(!infoIsOpen)}
            className="hover:cursor-pointer agenda-items font-medium text-right"
          >
            INFO
          </button>
          {ticketStatus === 'free' ? <p className="col-span-3 font-medium text-right">GRATIS</p>
            : (
              <a href={ticketsUrl} target="_blank" className="col-span-3 agenda-items font-medium text-right" rel="noreferrer">
                {ticketStatus === 'reserveren' ? 'RESERV'
                  : ticketStatus === 'inschrijven' ? 'INSCHR'
                    : ticketStatus.toLocaleUpperCase().replace('_', ' ')}
              </a>
            )}
        </div>
        <div
          style={
          (infoIsOpen && infoRefMobile && infoRefMobile.current)
            ? { maxHeight: infoRefMobile.current.clientHeight }
            : { maxHeight: 0 }
          }
          className={cx('col-span-full overflow-hidden transition-all duration-300 text-left')}
        >
          <div ref={infoRefMobile} className="pt-3 pb-4 agenda-items-s  space-y-[1vw]">
            <PortableText value={info} components={myPortableTextComponents} />
            { eventUrl && <a href={eventUrl} target="_blank" className="!mt-[3vw] block underline lg:no-underline lg:hover:underline" rel="noreferrer">FACEBOOKEVENT</a>}
          </div>
        </div>
      </div>

      <div className="hidden lg:grid grid-cols-24 items-start border-b-2 border-b-black pt-3 agenda-items agenda-items-padding">
        {hasBeginAndEndDate && (
          <span className="col-span-4 font-medium text-left pt-[0.2vw]">
            <time>{`${parsedStartDate}`}</time>
                  &nbsp;-&nbsp;
            <time>{`${parsedEndDate}`}</time>
          </span>
        )}
        {!hasBeginAndEndDate && (
          <time className="col-span-2 font-medium text-left pt-[0.2vw]">
            {dayOfWeek}
          </time>
        )}
        {!hasBeginAndEndDate && (
        <time className="col-span-2 font-medium text-left pt-[0.2vw]">{`${parsedDate}`}</time>
        )}
        <div className="col-span-5 font-medium text-left pt-[0.2vw]">
          {type.toLocaleUpperCase().replace('_', ' ')}
        </div>
        <div className="col-span-10 font-medium text-left">
          {name}
        </div>
        <button
          type="button"
          onClick={() => setInfoIsOpen(!infoIsOpen)}
          className="col-span-2 hover:cursor-pointer hover:underline font-medium text-right"
        >
          INFO
        </button>
        {ticketStatus === 'free' ? <p className="col-span-3 font-medium text-right">GRATIS</p>
          : (
            <a
              href={ticketsUrl}
              target="_blank"
              className="col-span-3 font-medium text-right hover:underline"
              rel="noreferrer"
            >
              {ticketStatus === 'reserveren' ? 'RESERV'
                : ticketStatus === 'inschrijven' ? 'INSCHR'
                  : ticketStatus.toLocaleUpperCase().replace('_', ' ')}
            </a>
          )}
        <div
          style={(infoIsOpen && infoRefDesktop && infoRefDesktop.current)
            ? { maxHeight: infoRefDesktop.current.clientHeight }
            : { maxHeight: 0 }}
          className={cx('col-start-10 col-span-full agenda-items-s overflow-hidden  transition-all duration-300 text-left')}
        >
          <div ref={infoRefDesktop} className="pt-4 pb-5 agenda-items-s space-y-[1vw]">
            <PortableText value={info} components={myPortableTextComponents} />
            {eventUrl && <a href={eventUrl} target="_blank" className="!mt-[2vw] block underline lg:no-underline lg:hover:underline" rel="noreferrer">FACEBOOKEVENT</a>}
          </div>
        </div>
      </div>
    </article>
  );
}

const eventTypes = ['all', 'club', 'concert', 'dj', 'bar_bricolage', 'markt', 'expo'] as const;

const timeFilters = ['binnenkort', 'voorbij'] as const;

const currentYear = new Date().getFullYear();

export const PROJECT_ID = 'w4h8hq3w';
export const DATASET = 'production';
export const QUERY = encodeURIComponent(`*[_type == "event" && (date >= '${currentYear}-01-01' || endDate >= '${currentYear}-01-01')]`);

const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

function Agenda(): JSX.Element {
  const [initialFutureEvents, setInitialFutureEvents] = React.useState<[] | AgendaItemProps[]>([]);
  const [initialPassedEvents, setInitialPassedEvents] = React.useState<[] | AgendaItemProps[]>([]);

  const [activeEvents, setActiveEvents] = React.useState<[] | AgendaItemProps[]>([]);

  const [activeFilters, setActiveFilters] = React.useState<string[]>(['all']);
  const [activeTimeFilter, setActiveTimeFilter] = React.useState<string>('binnenkort');

  const currentDate = new Date();

  React.useEffect(() => {
    async function fetchEvents() {
      try {
        const { data: { result } } = await axios.get(URL);

        const passedEvents: AgendaItemProps[] = [];
        const futureEvents: AgendaItemProps[] = [];

        result.forEach((agendaItem) => {
          const parsedDate = agendaItem.hasBeginAndEndDate
            ? new Date(agendaItem.endDate)
            : new Date(agendaItem.date);
          if ((currentDate.getDate() === parsedDate.getDate()
              && currentDate.getMonth() === parsedDate.getMonth()
              && currentDate.getFullYear() === parsedDate.getFullYear()
          ) || currentDate.getTime() < parsedDate.getTime()) {
            futureEvents.push(agendaItem);
          } else {
            passedEvents.push(agendaItem);
          }
        });

        const sortedFutureResult = futureEvents.sort(
          (
            {
              date: firstDate,
              startDate: firstStartDate, hasBeginAndEndDate:
                firstHasBeginAndEndDate,
            },
            {
              date: secondDate,
              startDate: secondStartDate,
              hasBeginAndEndDate: secondHasBeginAndEndDate,
            },
          ) => {
            const firstDateParsed = firstHasBeginAndEndDate
              ? new Date(firstStartDate) : new Date(firstDate);
            const secondDateParsed = secondHasBeginAndEndDate
              ? new Date(secondStartDate) : new Date(secondDate);

            if (firstDateParsed.getTime() > secondDateParsed.getTime()) {
              return 1;
            } if (new Date(firstDateParsed).getTime() === new Date(secondDateParsed).getTime()) {
              return 0;
            }
            return -1;
          },
        );

        setInitialFutureEvents(sortedFutureResult);
        setActiveEvents(sortedFutureResult);

        const sortedPassedResult = passedEvents.sort(
          (
            {
              date: firstDate,
              startDate: firstStartDate, hasBeginAndEndDate:
                  firstHasBeginAndEndDate,
            },
            {
              date: secondDate,
              startDate: secondStartDate,
              hasBeginAndEndDate: secondHasBeginAndEndDate,
            },
          ) => {
            const firstDateParsed = firstHasBeginAndEndDate
              ? new Date(firstStartDate)
              : new Date(firstDate);
            const secondDateParsed = secondHasBeginAndEndDate
              ? new Date(secondStartDate)
              : new Date(secondDate);

            if (firstDateParsed.getTime() > secondDateParsed.getTime()) {
              return 1;
            } if (firstDateParsed.getTime() === secondDateParsed.getTime()) {
              return 0;
            }
            return -1;
          },
        );

        setInitialPassedEvents(sortedPassedResult);
      } catch (error) {
        console.error(error);
      }
    }
    fetchEvents();
  }, []);

  function handleFilterClick(selectedFilter: string) {
    let initialEvents;
    if (activeTimeFilter === 'voorbij') {
      initialEvents = initialPassedEvents;
    } else {
      initialEvents = initialFutureEvents;
    }
    if (selectedFilter === 'all') {
      setActiveFilters(['all']);
      setActiveEvents(initialEvents);
      return;
    }
    if (activeFilters.includes(selectedFilter)) {
      const newActiveFilters = activeFilters.filter((type) => type !== selectedFilter);
      setActiveFilters(newActiveFilters);
      let newItems;
      if (newActiveFilters.length === 0) {
        newItems = initialEvents;
      } else {
        newItems = initialEvents.filter(({ type }) => newActiveFilters.includes(type));
      }
      setActiveEvents(newItems);
    } else {
      const newActiveTypes = [...activeFilters.filter((type) => type !== 'all'), selectedFilter];
      setActiveFilters(newActiveTypes);
      const newItems = initialEvents.filter(({ type }) => newActiveTypes.includes(type));
      setActiveEvents(newItems);
    }
  }

  function handleTimeFilterClick(selectedFilter: string) {
    setActiveTimeFilter(selectedFilter);
    setActiveFilters(['all']);
    if (selectedFilter === 'voorbij') {
      setActiveEvents(initialPassedEvents);
    } else {
      setActiveEvents(initialFutureEvents);
    }
  }

  return (
    <section className="w-screen pb-16">
      <h1 className="h-auto lg:h-[calc(1080 / 1920 * 100vw)] w-screen flex items-end ">
        <AgendaSvg />
      </h1>
      <div className="mt-[4vw] px-[2.5vw]">

        <div className="flex flex-wrap justify-between gap-x-[1.5vw] mb-6">
          {eventTypes.map((eventType) => (
            <button
              key={eventType}
              type="button"
              name={`${eventType}-filter-button`}
              className={cx('filter-button inline-block text-left font-medium', {
                underline: activeFilters.includes(eventType),
              })}
              onClick={() => handleFilterClick(eventType)}
            >
              {eventType.toLocaleUpperCase().replace('_', ' ')}
            </button>
          ))}
        </div>

        <div className="flex flex-wrap gap-x-6 mb-6">
          {timeFilters.map((timeFilter) => (
            <button
              key={timeFilter}
              type="button"
              name={`${timeFilter}-filter-button`}
              className={cx('filter-button inline-block text-left font-medium', {
                underline: activeTimeFilter === timeFilter,
              })}
              onClick={() => handleTimeFilterClick(timeFilter)}
            >
              {timeFilter.toLocaleUpperCase().replace('_', ' ')}
            </button>
          ))}
        </div>

        {activeEvents.map(({
          _id,
          name,
          hasBeginAndEndDate,
          startDate,
          endDate,
          date,
          type,
          info,
          eventUrl,
          ticketsUrl,
          ticketStatus,
        }) => (
          <AgendaItem
            key={_id}
            name={name}
            hasBeginAndEndDate={hasBeginAndEndDate}
            startDate={startDate}
            endDate={endDate}
            date={date}
            type={type}
            info={info}
            eventUrl={eventUrl}
            ticketsUrl={ticketsUrl}
            ticketStatus={ticketStatus}
          />
        ))}
      </div>
    </section>
  );
}

export default React.memo(Agenda);
